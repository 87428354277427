import React, { useReducer, useEffect, useState, useMemo } from 'react'

import { initialState, initializer } from '../authReducer/authReducer'
import { AuthDispatchContext, AuthStateContext } from '../authContext/AuthContext'
import { selectedCharityReducer, initialSelectedCharityState } from '../selectedCharityReducer/selectedCharityReducer'

import {
  authReducer,
  SET_AUTHORISED,
  SET_UNAUTHORISED,
  SET_LOGOUT,
  SET_SESSION_EXPIRED
} from '@percent/cause-dashboard/context/auth'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { AuthContextControllerProps } from './AuthContextController.types'
import { CurrencyInfo } from '@percent/cause-dashboard/services/donations/donationsService'
import { environment } from '@percent/cause-dashboard/config'
import { environments } from '@percent/cause-dashboard/constants/environments'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { AuthAction } from '../authReducer/authReducer.types'
import { CausePortalPermission } from '@percent/cause-dashboard/services/iam/permission.types'

export function AuthContextController({ children }: AuthContextControllerProps) {
  const [authState, authDispatch] = useReducer(authReducer, initialState, initializer)
  const [selectedCharityState, selectedCharityDispatch] = useReducer(
    selectedCharityReducer,
    initialSelectedCharityState
  )
  const [currencyInfoState, setCurrencyInfoState] = useState<CurrencyInfo[] | undefined>(undefined)
  const { identify } = useCausesPortalAnalytics()
  const state = useMemo(() => ({ authState, selectedCharityState }), [authState, selectedCharityState])
  const currencyInfoStateMemoized = useMemo(() => currencyInfoState, [currencyInfoState])

  const dispatch = useMemo(() => {
    const authDispatchWrapper = (action: AuthAction) => {
      switch (action.type) {
        case SET_AUTHORISED:
          localStorage.authState = JSON.stringify({
            isAuthorised: true,
            isAuthorising: false
          })
          break
        case SET_LOGOUT:
          localStorage.removeItem('authState')
          break
        case SET_SESSION_EXPIRED:
          localStorage.authState = JSON.stringify({
            isAuthorised: false,
            isAuthorising: false,
            sessionExpired: true
          })
          break
        default:
          break
      }

      return authDispatch(action)
    }

    return {
      authDispatch: authDispatchWrapper,
      selectedCharityDispatch
    }
  }, [authDispatch, selectedCharityDispatch])

  const [userPermissionsState, setUserPermissionsState] = useState<CausePortalPermission[]>([])

  const { authService, accountService, iamService, claimService, causeService, donationsService, bankDetailsService } =
    useServices()

  useEffect(() => {
    if (userPermissionsState.length) return

    const getUserPermissions = async () => {
      try {
        const {
          data: { data: userPermissions, error }
        } = await iamService.getPermissions()
        if (error && error.code === 'iam/invalid_team_id') {
          setUserPermissionsState([])
          return
        }

        setUserPermissionsState(userPermissions)
      } catch {
        setUserPermissionsState([])
      }
    }

    if (state.authState.isAuthorised && !state.authState.isAuthorising) {
      void getUserPermissions()
    }
  }, [state.authState.isAuthorised, state.authState.isAuthorising, iamService])

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await accountService.getAccount()
        let organisation
        let claimOrganisation

        if (data?.organisationId) {
          const { data: orgData } = await accountService.getAccountOrganisation()
          organisation = orgData.data
        }

        const {
          data: { data: claims }
        } = await claimService.getClaims()

        if (claims[0]?.id) {
          const claimOrg = await causeService.getOrganisation({
            organisationId: claims[0]?.organisationId
          })
          claimOrganisation = {
            ...claims[0],
            ...claimOrg
          }
        }

        const {
          data: { data: currencyInfoData }
        } = await donationsService.getCurrencyInfo()
        setCurrencyInfoState(currencyInfoData)

        if ([environments.PRODUCTION].includes(environment as string)) {
          window?.Appcues?.identify(data?.id, {
            email: data?.email,
            nonProfitName: organisation?.name
          })
        }

        if (claims[0]?.acceptedAt) {
          const orgBankDetails = await bankDetailsService.getBankDetails()

          return dispatch.authDispatch({
            type: SET_AUTHORISED,
            payload: {
              user: data,
              organisation,
              claimOrganisation,
              orgBankDetails: orgBankDetails
            }
          })
        }

        return dispatch.authDispatch({
          type: SET_AUTHORISED,
          payload: {
            user: data,
            organisation,
            claimOrganisation
          }
        })
      } catch {
        return dispatch.authDispatch({
          type: SET_UNAUTHORISED
        })
      }
    }

    if (state.authState.isAuthorised && !state.authState.isAuthorising) {
      getUserData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.authState.isAuthorised, state.authState.isAuthorising, authService, accountService])

  useEffect(() => {
    if (state.authState.claimOrganisation) {
      identify({
        traits: {
          organisation: state.authState.claimOrganisation?.name,
          organisationId: state.authState.claimOrganisation?.id,
          country: state.authState.claimOrganisation?.countryCode
        }
      })
    }
  }, [state.authState.claimOrganisation])

  return (
    <AuthStateContext.Provider
      value={{
        ...state,
        currencyInfo: currencyInfoStateMemoized,
        userPermissions: userPermissionsState
      }}
    >
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}
