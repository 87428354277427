import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { Button } from '@percent/lemonade'
import { ChangeTwoFactorDeviceContainer } from './ChangeTwoFactorDevice/ChangeTwoFactorDeviceContainer'
import { DisableTwoFactorDeviceContainer } from './DisableTwoFactorDevice/DisableTwoFactorDeviceContainer'
import styles from './TwoFACard.module.scss'

export default function TwoFACard() {
  const { t } = useTranslation()
  const [isChangeTwoFactorDeviceModalOpened, setIsChangeTwoFactorDeviceModalOpened] = useState(false)
  const [isDisableTwoFactorDeviceModalOpened, setIsDisableTwoFactorDeviceModalOpened] = useState(false)
  const {
    authState: { user }
  } = useAuthState()

  const [checked, setChecked] = React.useState(!!user?.twoFaSecretVerifiedAt)

  return (
    <>
      <div className={styles.optionContainer}>
        <div className={styles.textContainer}>
          <p className={styles.subtext}>{t('typography.2faSubtext')}</p>
        </div>
        <div className={styles.buttonContainer}>
          {checked ? (
            <Button variant="secondary" onPress={() => setIsDisableTwoFactorDeviceModalOpened(true)}>
              {t('2fa.disable')}
            </Button>
          ) : (
            <Button variant="secondary" onPress={() => setIsChangeTwoFactorDeviceModalOpened(true)}>
              {t('2fa.enable')}
            </Button>
          )}
        </div>
      </div>
      <ChangeTwoFactorDeviceContainer
        isOpened={isChangeTwoFactorDeviceModalOpened}
        onClose={() => setIsChangeTwoFactorDeviceModalOpened(false)}
      />
      <DisableTwoFactorDeviceContainer
        isOpened={isDisableTwoFactorDeviceModalOpened}
        onClose={() => setIsDisableTwoFactorDeviceModalOpened(false)}
        setChecked={setChecked}
      />
    </>
  )
}
