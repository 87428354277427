import { useEffect, useState } from 'react'

import { useAnalytics } from '@percent/cause-dashboard/common/hooks/useAnalytics/useAnalytics'
import { getDeviceType } from '@percent/cause-dashboard/utils/deviceType'

const redactToken = (text: string) => {
  const endIndex = text.search('token=') + 6

  return text ? text.substring(0, endIndex).concat('redacted') : ''
}

const pageRedact = {
  url: redactToken(window?.location?.href),
  search: redactToken(window?.location?.search),
  referrer: redactToken(document?.referrer)
}

const defaultOptions = { domain: 'Causes portal' }

const defaultContext = {
  app: { name: 'Causes portal', id: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA },
  device: { type: getDeviceType() },
  ip: '0.0.0.0',
  ...pageRedact,
  page: {
    ...pageRedact
  }
}

export const useCausesPortalAnalytics = () => {
  const analytics = useAnalytics()
  const [analyticsLoaded, setAnalyticsLoaded] = useState<boolean>(!!analytics)

  const page = async (page: string, options?: Record<string, unknown>) => {
    analytics?.page(
      'Page',
      page,
      {
        ...defaultOptions,
        ...options,
        ...pageRedact
      },
      { context: defaultContext }
    )
  }

  const identify = async ({ id, traits }: { id?: string; traits?: Record<string, unknown> }) =>
    analytics?.identify(id, { ...traits }, { context: defaultContext })

  const track = async (name: string, options?: Record<string, unknown>) =>
    analytics?.track(
      name,
      {
        ...defaultOptions,
        ...options,
        ...pageRedact
      },
      { context: defaultContext }
    )

  useEffect(() => {
    setAnalyticsLoaded(!!analytics)
  }, [analytics])

  return {
    page,
    identify,
    track,
    analyticsLoaded
  }
}
