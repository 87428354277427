import { apiPaths } from '../apiPaths'
import { AddOrganizationProps, OrganizationServiceCapabilities } from './organization.types'

export const organizationService = ({ percentClient }: OrganizationServiceCapabilities) => ({
  addOrganization: ({ formData, registryNameFallback }: AddOrganizationProps) => {
    const form = new FormData()
    const websiteUrl = (!/^https?:\/\//i.test(formData.website) && `https://${formData.website}`) || formData.website

    form.append('countryCode', formData.country)
    form.append('organisationName', formData.name)
    form.append('website', websiteUrl)
    form.append('registryName', formData.registry || registryNameFallback)
    form.append('registryId', formData.registryId)
    form.append('userEmail', formData.email)
    form.append('file', formData.documentProof)

    return percentClient.post(`${apiPaths.v1VSOrganization}`, form)
  }
})
