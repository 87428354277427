import { Grid } from '@material-ui/core'

import styles from '../causeProfile/ProfilePage.module.scss'

import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { TitleCard } from '@percent/cause-dashboard/common/components/titleCard/TitleCard'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useTranslation } from 'react-i18next'
import CommPreferencesCard from './CommunicationPreferences/CommPreferencesCard'
import { MyAccountForm } from './MyAccountForm/MyAccountForm'
import TwoFA from './TwoFA/TwoFACard'

export function MyAccountContainer() {
  const { accountService } = useServices()
  const [{ data, isLoading }, { refresh }] = useQuery(accountService.getAccount, {})
  const { t } = useTranslation()

  const fullName = data?.fullName
  const phoneNumber = data?.phoneNumber
  const preferredName = data?.preferredName

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Grid container spacing={4} className={styles.profilePageGrid}>
        <TitleCard title={t('card.myAccount')}>
          <MyAccountForm name={fullName} number={phoneNumber} handle={preferredName} refresh={refresh} />
        </TitleCard>
      </Grid>
      <Grid container spacing={4} className={styles.profilePageGrid}>
        <TitleCard title={t('typography.2faOption')}>
          <TwoFA />
        </TitleCard>
      </Grid>
      <Grid container spacing={4} className={styles.profilePageGrid}>
        <TitleCard title={t('typography.communicationPreferences')}>
          <CommPreferencesCard />
        </TitleCard>
      </Grid>
    </>
  )
}
