import { AxiosInstance } from 'axios'

import { apiPaths, baseUrl } from '@percent/cause-dashboard/services/apiPaths'
import { PBPListResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { Donation, GetDonationsListProps } from './donationsService.types'

type donationsServiceCapabilities = {
  percentClient: AxiosInstance
}

export type CurrencyInfo = {
  code: string
  createdAt: string
  name: string
  minimum: number
  maximum: number
  defaultValue: number
  symbol: string
  encodedSymbol: string
  minorUnits: number
  flagCode: string
  supportedByStripe: boolean
  updateAt: string
}

export const donationsService = ({ percentClient }: donationsServiceCapabilities) => ({
  getDonationsList: (organisationId: string) => (params: GetDonationsListProps) =>
    percentClient.get<PBPListResponse<Donation>>(`${baseUrl}organisations/${organisationId}/donations`, {
      params
    }),
  getCurrencyInfo: () => percentClient.get<PBPListResponse<CurrencyInfo>>(apiPaths.v1Currencies)
})
