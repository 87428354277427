import { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useLocation } from 'react-router'

import styles from './AgentVerificationEmailValidation.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'
import { EmailFooter } from '@percent/cause-dashboard/common/components/emailFooter/EmailFooter'
import { ExperimentSignUpToViewProgress } from '@percent/cause-dashboard/app/experiments/ExperimentSignUpToViewProgress'
import { Alert } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { ExperimentFastTrackGoogleApplication } from '../../experiments/ExperimentFastTrackGoogleApplication'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components/centralCardLayoutWrapper/centralCardLayoutWrapper'

export const authErrorCodeToLocaleMap = {
  'agent_verification_user_verification/invalid_token': {
    title: 'errorMessage.avFailedInvalidTokenTitle',
    description: 'errorMessage.avFailedInvalidTokenDesc'
  },
  '*': {
    title: 'errorMessage.avFailedOtherTitle',
    description: 'errorMessage.avFailedOtherDesc'
  }
} as const

export const AgentVerificationEmailValidation = () => {
  const { t } = useTranslation()
  const { t: loco, i18n } = useTranslation('causeDashboardLoco')
  const { search } = useLocation()
  const { agentVerificationService } = useServices()
  const agentVerificationToken = new URLSearchParams(search).get('agentVerificationToken') || ''

  const [{ isLoading, data, error }] = useQuery(
    agentVerificationService.getConfirmAgentVerificationRequest,
    agentVerificationToken
  )
  const responseData = data?.data?.data

  useEffect(() => {
    if (data?.data?.data?.language) {
      i18n.changeLanguage(data?.data?.data?.language)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (isLoading) {
    return <Loader />
  }

  if (error || !responseData) {
    const errorCode = error?.code || 'request_error'

    const errorObject = Object.keys(authErrorCodeToLocaleMap).includes(errorCode)
      ? authErrorCodeToLocaleMap[error?.code as keyof typeof authErrorCodeToLocaleMap]
      : authErrorCodeToLocaleMap['*']

    return (
      <CentralCardLayoutWrapper heading={t('errorMessage.avFailedPageTitle')}>
        <Alert variant={'error'} title={t(errorObject.title)}>
          {t(errorObject.description)}
        </Alert>
      </CentralCardLayoutWrapper>
    )
  }
  return (
    <CentralCardLayoutWrapper
      heading={loco('agent_verification_confirmation.success_page.heading.thank_you')}
      AgentVerificationLogo={data?.data?.data?.logo}
      agentVerificationLink={data?.data?.data?.agentVerificationRedirectUrl}
      CustomFooter={EmailFooter}
      alignCenter
    >
      <Typography variant="subtitle2" className={styles.agentVerificationText}>
        {loco('agent_verification_confirmation.success_page.body.application_being_processed')}
      </Typography>

      {/* These two experiments cannot show at the same time due to partner include/exclude lists */}
      <ExperimentFastTrackGoogleApplication
        organizationId={responseData?.organisationId ?? ''}
        partnerId={responseData?.partnerId ?? ''}
        userLanguage={responseData?.language ?? 'en'}
        email={responseData?.email ?? ''}
      />

      <ExperimentSignUpToViewProgress
        organizationId={responseData?.organisationId ?? ''}
        partnerId={responseData?.partnerId ?? ''}
        userLanguage={responseData?.language ?? 'en'}
        email={responseData?.email ?? ''}
      />
    </CentralCardLayoutWrapper>
  )
}
