export const translationResources = {
  'en-US': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en.json')
  },
  'en-GB': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'de-DE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/de-DE.json')
  },
  'fr-FR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-FR.json')
  },
  'fr-CA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-CA.json')
  },
  'pt-BR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pt-BR.json')
  },
  'es-ES': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-ES.json')
  },
  'bg-BG': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/bg-BG.json')
  },
  'es-419': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'af-ZA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/af-ZA.json')
  },
  'hi-IN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/hi-IN.json')
  },
  'id-ID': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/id-ID.json')
  },
  'ja-JP': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ja-JP.json')
  },
  'ko-KR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ko-KR.json')
  },
  'th-TH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/th-TH.json')
  },
  'ur-IN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ur-IN.json')
  },
  'vi-VN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/vi-VN.json')
  },
  'zh-Hans': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hans.json')
  },
  'zh-Hant': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  ar: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'bs-BA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/bs-BA.json')
  },
  'cs-CZ': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/cs-CZ.json')
  },
  'da-DK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/da-DK.json')
  },
  'fi-FI': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fi-FI.json')
  },
  'he-IL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/he-IL.json')
  },
  'hu-HU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/hu-HU.json')
  },
  'it-IT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/it-IT.json')
  },
  'lv-LV': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/lv-LV.json')
  },
  'nl-NL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/nl-NL.json')
  },
  'pl-PL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pl-PL.json')
  },
  'pt-PT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pt-PT.json')
  },
  'ro-RO': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ro-RO.json')
  },
  'ru-RU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ru-RU.json')
  },
  'sk-SK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sk-SK.json')
  },
  'sl-SI': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sl-SI.json')
  },
  'sv-SE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sv-SE.json')
  },
  sw: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sw.json')
  },
  'tr-TR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/tr-TR.json')
  },
  'uk-UA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/uk-UA.json')
  },
  'zu-ZA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zu-ZA.json')
  }
}
