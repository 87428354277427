import * as Yup from 'yup'
import { RequiredNumberSchema } from 'yup/lib/number'
import { ObjectShape } from 'yup/lib/object'
import { RequiredStringSchema } from 'yup/lib/string'

export type ItemModalProps = {
  cancel: () => void
  save: (success: boolean) => void
}

export const schema = Yup.object().shape({
  amount: Yup.number()
    .moreThan(0)
    .max(15000000)
    .test({
      name: 'amount',
      exclusive: true,
      message: 'Amount should be provided with two decimal places e.g. 5.99',
      test: value => {
        if (value === undefined) {
          return false
        }

        if (!/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(value.toString())) {
          return false
        }

        return true
      }
    })
    .required('Required') as RequiredNumberSchema<number>,
  reward: Yup.string()
    .trim()
    .required('Required')
    .max(255, 'Should not exceed more than 255 characters') as RequiredStringSchema<string>
})

export const validationSchema = schema.required() as typeof schema

export type ShoppingItem = {
  amount: number
  reward: string
}
