import { useLocation } from 'react-router'

import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'

import { AgentVerify } from './AgentVerify'
import { useEffect, useState } from 'react'
import { AgentVerificationAssociation } from '@percent/cause-dashboard/services/agentVerification/AgentVerification.types'
import { AxiosError } from 'axios'

export function AgentVerifyContainer() {
  const { agentVerificationService } = useServices()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token') || ''
  const answer = new URLSearchParams(search).get('answer') || ''
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<undefined | AxiosError<{ code: string }>>()
  const [agentData, setAgentData] = useState<AgentVerificationAssociation>()

  const sendData = async () => {
    setIsLoading(true)
    try {
      const {
        data: { data: agentVerifyData }
      } = await agentVerificationService.postAgentVerificationAssociation({
        verificationToken: token,
        isAssociatedWithOrganisation: answer === 'yes'
      })

      if (!agentData) {
        setAgentData(agentVerifyData)
      }
      setIsLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError(err?.response?.data?.error ?? err?.message)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    sendData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AgentVerify data={agentData} isLoading={isLoading} error={error} />
}
