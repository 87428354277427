import React from 'react'
import { CircularProgress } from '@material-ui/core'

import styles from './Loader.module.scss'

type LoaderProps = {
  loaderStyle?: string
}

export function Loader({ loaderStyle = 'loader' }: LoaderProps) {
  return (
    <div className={styles[loaderStyle]}>
      <CircularProgress className={styles.circularProgress} thickness={5} data-testid="loading" />
    </div>
  )
}
