import React from 'react'

import { ModalContainer } from '@percent/cause-dashboard/common/components/modal/ModalContainer'
import { ProofBankErrorModalProps } from './ProofBankErrorModal.types'
import { useTranslation } from 'react-i18next'
import { AcknowledgeModal } from '@percent/lemonade'

export function ProofBankErrorModal({
  openProofBankErrorModal,
  handleCloseProofBankErrorModal
}: ProofBankErrorModalProps) {
  const { t } = useTranslation()

  return (
    <ModalContainer openModal={openProofBankErrorModal} onClose={handleCloseProofBankErrorModal}>
      <AcknowledgeModal
        title={t('dialog.proofBankErrorModal.title')}
        handleClose={handleCloseProofBankErrorModal}
        description={t('dialog.proofBankErrorModal.subTitle')}
        result={'negative'}
        buttonText={t('button.done')}
        buttonTestId="modal-add-item-success-button"
      />
    </ModalContainer>
  )
}
