import { UsersListTable } from '@percent/cause-dashboard/app/users/UsersListTable/UsersListTable'
import { useQueryList } from '@percent/cause-dashboard/common/hooks'
import { usePermissionCheck } from '@percent/cause-dashboard/common/hooks/usePermissionCheck/usePermissionCheck'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Button, Heading, Tab, Tabs } from '@percent/lemonade'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { InviteUserModal } from './InviteUserModal/InviteUserModal'
import { InviteesListTable } from './InviteesListTable/InviteesListTable'
import styles from './UsersPage.module.scss'

export function UsersPage() {
  const [showInviteModal, setShowInviteModal] = useState(false)
  const { t } = useTranslation()
  const { iamService } = useServices()

  type TabName = 'members' | 'invites'

  const tabs: { label: string; key: TabName }[] = [
    {
      label: t('typography.userManagement.members'),
      key: 'members'
    },
    {
      label: t('typography.userManagement.invites'),
      key: 'invites'
    }
  ]

  const [activeTab, setActiveTab] = useState<TabName>('members')

  const [
    { data: accountsData, totalResults: totalAccounts, isLoading: isLoadingAccounts },
    { nextPage: nextPageAccounts, previousPage: previousPageAccounts, refresh: refreshAccounts }
  ] = useQueryList(iamService.getAccounts)

  const [
    { data: invitesData, totalResults: totalInvites, isLoading: isLoadingInvites },
    { nextPage: nextPageInvites, previousPage: previousPageInvites, refresh: refreshInvites }
  ] = useQueryList(iamService.getInvites)

  const handleInviteClick = () => {
    setShowInviteModal(true)
  }

  const { userCan } = usePermissionCheck()
  const canInviteUsers = userCan('organization_users:write')
  const location = useLocation()
  const [email, setEmail] = useState('')

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const email = query.get('email')
    const action = query.get('action')
    if (action && action === 'invite' && email) {
      setEmail(email)
      setShowInviteModal(true)
    }
  }, [location])

  const onInviteModalClose = (shouldRefreshInvites: boolean) => {
    setShowInviteModal(false)
    if (shouldRefreshInvites) {
      refreshInvites()
    }
  }

  return (
    <div className={styles.userPageWrapper} data-testid="users-page">
      <InviteUserModal open={showInviteModal} onClose={onInviteModalClose} email={email} />
      <div className={styles.userPageContainer}>
        <div className={styles.userPageHeader}>
          <Heading level={'h6'}>{t('typography.userManagement.myTeam')}</Heading>
          {canInviteUsers && (
            <Button size="small" onPress={handleInviteClick} data-testid="invite-btn">
              {t('button.invite')}
            </Button>
          )}
        </div>
        <Tabs
          items={tabs}
          activeTab={activeTab}
          onChangeTab={newTab => {
            setActiveTab(newTab as TabName)
          }}
        />
        <Tab data-testid={'tab-content-members'} index={'members'} value={activeTab}>
          <UsersListTable
            data={accountsData}
            isLoading={isLoadingAccounts}
            totalResultCount={totalAccounts}
            onNextPageClick={nextPageAccounts}
            onPreviousPageClick={previousPageAccounts}
            onRefetchData={refreshAccounts}
          />
        </Tab>
        <Tab data-testid={'tab-content-invites'} index={'invites'} value={activeTab}>
          <InviteesListTable
            data={invitesData}
            isLoading={isLoadingInvites}
            totalResultCount={totalInvites}
            onNextPageClick={nextPageInvites}
            onPreviousPageClick={previousPageInvites}
          />
        </Tab>
      </div>
    </div>
  )
}
