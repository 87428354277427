import styles from './ExperimentSignUpToViewProgress.module.scss'
import { Typography } from '@material-ui/core'
import { Button, Spacer } from '@percent/lemonade'
import { config } from '@percent/cause-dashboard/config'
import { useExperiment } from '@percent/cause-dashboard/common/hooks/useExperiment/useExperiment'
import { teamIcedLatteExperiments } from '@percent/cause-dashboard/common/hooks/useExperiment/experiments/teamIcedLatteExperiments'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getSignUpUrl } from '@percent/cause-dashboard/common/utility/utility'
import { SignUpReason } from '@percent/cause-dashboard/common/hooks/useSignUpAttribution/useSignUpAttribution'

interface ExperimentSignUpToViewProgressProps {
  partnerId: string
  userLanguage: string
  organizationId?: string
  email?: string
}

export const ExperimentSignUpToViewProgress = ({
  partnerId,
  organizationId,
  userLanguage,
  email
}: ExperimentSignUpToViewProgressProps) => {
  const signUpToTrackProgressExperiment = useExperiment(
    teamIcedLatteExperiments.signUpToTrackVerificationStatus,
    partnerId
  )

  useEffect(() => {
    signUpToTrackProgressExperiment.trackEventOnceThisSession('logPageView')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleExperimentCtaClick = () => {
    signUpToTrackProgressExperiment.trackEventOnceThisSession('clickCta')

    const signUpUrl = getSignUpUrl({
      organizationId,
      email,
      signUpPartnerId: partnerId,
      signUpReason: SignUpReason.VERIFICATION_STATUS_CHECK
    })

    const url = `${config.urls.app}${signUpUrl}`
    window.open(url)
  }

  const { t } = useTranslation()

  if (
    !signUpToTrackProgressExperiment.canPerformExperiment ||
    !organizationId ||
    !userLanguage.toLowerCase().includes('en')
  ) {
    return null
  }

  return (
    <div className={styles.ctaContainer} data-testid="progress-view-experiment">
      <Typography variant={'subtitle2'} className={styles.ctaTitle}>
        {t('typography.signUpExperimentTitle')}
      </Typography>
      <div className={styles.ctaFlex}>
        <div className={styles.ctaText}>
          <div>
            <Typography variant="caption" className={styles.ctaText}>
              {t('typography.signUpExperimentDescription')}
            </Typography>
          </div>
        </div>
      </div>
      <Spacer size={4} axis="vertical" />
      <div className={styles.ctaFlex}>
        <Button size="large" variant="primary" stretch onPress={handleExperimentCtaClick}>
          {t('typography.signUpExperimentButtonText')}
        </Button>
      </div>
    </div>
  )
}
