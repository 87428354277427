import { AxiosInstance } from 'axios'

import { getDataFromPBPResponse } from '@percent/cause-dashboard/common/utility/pbpApi'
import { config } from '@percent/cause-dashboard/config'
import { apiPaths } from '@percent/cause-dashboard/services/apiPaths'
import { PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { Countries } from './countriesService.types'

type countriesServiceCapabilities = {
  percentClient: AxiosInstance
}

export const countriesService = ({ percentClient }: countriesServiceCapabilities) => ({
  getCountries: () =>
    percentClient
      .get<PBPResponse<Countries[]>>(apiPaths.v1Countries, {
        headers: {
          Authorization: config.keys.pk
        },
        params: {
          hasRegistry: true
        },
        withCredentials: false
      })
      .then(getDataFromPBPResponse)
})
