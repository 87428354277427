import React from 'react'
import { Typography } from '@material-ui/core'

import styles from './NoResultTable.module.scss'
import { NoResultTableProps } from './NoResultTable.types'

export function NoResultTable({ text, actionsRow, image, alertComponent }: NoResultTableProps) {
  return (
    <div className={`${styles.noResultTableContainer} ${alertComponent ? styles.heightWithAlert : styles.height}`}>
      {!!image && <img alt="no result" src={image} />}
      <Typography variant="h5" color="secondary">
        {text}
      </Typography>
      {actionsRow}
    </div>
  )
}
