import { useEffect, useMemo, useRef } from 'react'
import { stringifyUrl } from 'query-string'

import { config } from '@percent/cause-dashboard/config'
import { useFeatureFlag } from '@percent/cause-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

const WIDGET_ID = 'ze-snippet'

export function useChatWidget() {
  const { appStoreChatWidget } = useFeatureFlag()
  const scriptRef = useRef<HTMLScriptElement>()

  const isValidTimeOfDay = useMemo(() => {
    // in the working hours of
    const supportTimezones = {
      us_mdt: {
        utcStartHour: 0,
        utcEndHour: 23
      }
    }

    const isUtcHourInAnyValidSupportTimezone = (hourUtc: number): boolean => {
      for (const key in supportTimezones) {
        const supportUnit = supportTimezones[key as keyof typeof supportTimezones]
        if (hourUtc >= supportUnit.utcStartHour && hourUtc <= supportUnit.utcEndHour) {
          return true
        }
      }

      return false
    }

    const now = new Date()
    const day = now.getDay()
    const hour = now.getUTCHours()

    const isValid = day >= 1 && day <= 7 && isUtcHourInAnyValidSupportTimezone(hour)
    return isValid
  }, [])

  const shouldShowChatWidget = useMemo(() => {
    return appStoreChatWidget && isValidTimeOfDay
  }, [appStoreChatWidget, isValidTimeOfDay])

  useEffect(() => {
    if (!shouldShowChatWidget) {
      return
    }

    if (config.keys.zendeskChatKey) {
      const zendeskScript = document.getElementById(WIDGET_ID)

      if (zendeskScript) {
        scriptRef.current = zendeskScript as HTMLScriptElement
      } else {
        const widgetSrc = stringifyUrl({
          url: 'https://static.zdassets.com/ekr/snippet.js',
          query: {
            key: config.keys.zendeskChatKey
          }
        })

        const newZendeskScript = document.createElement('script')
        newZendeskScript.setAttribute('id', WIDGET_ID)
        newZendeskScript.setAttribute('src', widgetSrc)

        document.body.appendChild(newZendeskScript)
        scriptRef.current = newZendeskScript
      }
    }
  }, [shouldShowChatWidget])

  return scriptRef
}
