import { AxiosInstance } from 'axios'
import { ValidationSubmission } from './validationSubmission.types'
import { apiPaths } from '@percent/cause-dashboard/services/apiPaths'
import { PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'

type storeServiceCapabilities = {
  percentClient: AxiosInstance
}

export const storeService = ({ percentClient }: storeServiceCapabilities) => ({
  getValidationSubmission: (validationSubmissionSourceId: string) =>
    percentClient.get<PBPResponse<ValidationSubmission>>(
      `${apiPaths.v1StoreApplications}/${validationSubmissionSourceId}`
    )
})
