import { useContext } from 'react'

import { NotificationBarContext } from '@percent/cause-dashboard/context/notificationBar/notificationBarContext/NotificationBarContext'

export const useNotificationBar = () => {
  const context = useContext(NotificationBarContext)

  if (context === undefined) {
    throw new Error('useNotificationBar must be used within a NotificationBarContextController')
  }

  return context
}
