import en from '@percent/cause-dashboard/i18n/data/en/en.json'

export const API_ERROR_MESSAGE = {
  applicationNotFound: en['errorMessage.applicationNotFound'],
  endpointNotFound: en['errorMessage.endpointNotFound'],
  fieldError: en['errorMessage.fieldError'],
  fieldLength: en['errorMessage.fieldLength'],
  genericError: en['errorMessage.genericError'],
  internalSystemError: en['errorMessage.internalSystemError'],
  jsonInvalid: en['errorMessage.jsonInvalid'],
  loginError: en['errorMessage.loginError'],
  maxItemsPerPage: en['errorMessage.maxItemsPerPage'],
  methodDoesNotExist: en['errorMessage.methodDoesNotExist'],
  notLoggedIn: en['errorMessage.notLoggedIn'],
  oauthTrustOrSuspension: en['errorMessage.oauthTrustOrSuspension'],
  pathOneError: en['errorMessage.pathOneError'],
  permissionError: en['errorMessage.permissionError'],
  qrError: en['errorMessage.qrError'],
  storesInvalidMerchant: en['errorMessage.storesInvalidMerchant'],
  unauthorisedAction: en['errorMessage.unauthorisedAction'],
  imageFileSize: en['errorMessage.imageFileSize'],
  forbiddenPassword: en['errorMessage.forbiddenPassword'],
  tooManyChangePasswordRequests: en['errorMessage.tooManyChangePasswordRequests'],
  twoFaInvalidToken: en['errorMessage.twoFaInvalidToken'],
  twoFaInvalidTokenOtpCode: en['errorMessage.twoFaInvalidTokenOtpCode'],
  linkExpired: en['errorMessage.linkExpired'],
  rateExceeded: en['errorMessage.rateExceeded'],
  linkInvalid: en['errorMessage.linkInvalid'],
  tokenInvalid: en['errorMessage.tokenInvalid']
}
