import { CurrencyInfo } from '@percent/cause-dashboard/services/donations/donationsService'
import { Money } from './money.types'
import { formatMoney } from './money'

export const toCurrencyMap = (currencyInfo: CurrencyInfo[] | undefined): Record<string, CurrencyInfo> =>
  currencyInfo ? Object.fromEntries(currencyInfo.map(ci => [ci.code, ci])) : {}

export const currencyMapToFormat =
  (currencyMap: Record<string, CurrencyInfo>) =>
  (amount: Money): string =>
    formatMoney(amount, currencyMap[amount.currency]?.minorUnits ?? 2)
