import React from 'react'

import { useTranslation } from 'react-i18next'
import { Button, Spacer } from '@percent/lemonade'

export const causeFormBtnInlineStyle = {
  height: '40px',
  borderRadius: '8px'
}

type FormButtonProps = {
  disableInput: boolean
  dirty?: boolean
  handleSubmit?: () => void
  testId: string
  handleDisableState: () => void | undefined
  handleCancelForm: () => void
  type?: 'button' | 'submit' | 'reset'
}

export function FormButtons({
  handleSubmit,
  dirty = true,
  disableInput,
  testId,
  handleDisableState,
  handleCancelForm,
  type
}: FormButtonProps) {
  const { t } = useTranslation()

  return disableInput ? (
    <Button data-testid={testId} onPress={handleDisableState}>
      {t('button.editDetails')}
    </Button>
  ) : (
    <div>
      <Button data-testid={testId} type={type} disabled={!dirty} onPress={handleSubmit}>
        {t('button.saveChanges')}
      </Button>
      <Spacer size={5} />
      <Button variant="secondary" onPress={handleCancelForm}>
        {t('button.cancel')}
      </Button>
    </div>
  )
}
