import { Box, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { KeyboardArrowLeft, KeyboardArrowRight, Refresh } from '@material-ui/icons'
import React from 'react'

import { useTranslation } from 'react-i18next'
import styles from './PaginationActions.module.scss'

type PaginationActionsProps = {
  count: number
  nextPage: VoidFunction | null
  previousPage: VoidFunction | null
  refresh: VoidFunction | null
}

export function PaginationActions({ count, nextPage, previousPage, refresh }: PaginationActionsProps) {
  const { t } = useTranslation()

  const handleRefreshButtonClick = () => refresh?.()
  const handleBackButtonClick = () => previousPage?.()
  const handleNextButtonClick = () => nextPage?.()

  return (
    <Box className={styles.container}>
      <Typography>{`${count} ${count <= 1 ? t('typography.result') : t('typography.results')}`}</Typography>
      <div>
        {refresh ? (
          <IconButton
            onClick={handleRefreshButtonClick}
            className={styles.paginationButton}
            data-testid="refresh-button"
            aria-label="refresh"
          >
            <Refresh />
          </IconButton>
        ) : null}

        <IconButton
          onClick={handleBackButtonClick}
          disabled={!previousPage}
          aria-label="previous page"
          className={previousPage ? styles.paginationButton : styles.paginationButtonDisabled}
          data-testid="back-button"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={!nextPage}
          aria-label="next page"
          className={nextPage ? styles.paginationButton : styles.paginationButtonDisabled}
          data-testid="next-button"
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    </Box>
  )
}
