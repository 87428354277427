import styled from 'styled-components'
import ReactPhoneInput from 'react-phone-number-input'
import { PhoneInputProps } from './phone-input.types'
import 'react-phone-number-input/style.css'

export const StyledPhoneInput = styled(ReactPhoneInput)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  border-radius: ${({ theme }) => theme.borderRadii.medium};
  border: 1px solid ${({ theme }) => theme.colors.gray200};

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }

  &.PhoneInput--disabled {
    outline: none;
    border: none;
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.gray80};

    .PhoneInputCountry,
    .PhoneInputInput {
      background-color: ${({ theme }) => theme.colors.gray80};
      pointer-events: none;
    }
  }

  &:read-only {
    border-color: ${({ theme }) => theme.colors.gray80};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.black};
  }

  &:not(.PhoneInput--disabled):hover {
    border-color: ${({ theme }) => theme.colors.black};
    outline: 4px solid ${({ theme }) => theme.colors.gray80};
  }

  .PhoneInputCountrySelect,
  .PhoneInputInput {
    box-sizing: border-box;
    width: 100%;
    height: ${({ theme }) => theme.sizes[10]};
    border: none;
    outline: none;

    font-size: ${({ theme }) => theme.fontSizes.bodySmall};
    color: ${({ theme }) => theme.colors.black};
  }

  .PhoneInputInput::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }

  .PhoneInputCountry {
    margin-left: 8px;
  }

  .PhoneInputInput {
    flex-grow: 1;
    width: auto;
    padding-right: 0;
    border-radius: ${({ theme }) => theme.borderRadii.medium};
  }

  @media only screen and (max-device-width: 600px) {
    -webkit-appearance: none;
    font-size: ${({ theme }) => theme.sizes[4]};
  }
`

export const PhoneInput = (props: PhoneInputProps) => {
  return <StyledPhoneInput {...props} />
}
