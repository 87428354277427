import { apiPaths } from '../apiPaths'
import { CauseUserPayload, CauseUserServiceCapabilities, InsightQuestionSet } from './causeUserService.types'

export const causeUserService = ({ percentClient }: CauseUserServiceCapabilities) => ({
  createCauseUser: (payload: CauseUserPayload) => percentClient.post(`${apiPaths.v1CauseUsers}`, payload),
  getInsightQuestionSet: (questionSetId: string) =>
    percentClient.get<InsightQuestionSet>(`${apiPaths.v1InsightQuestionSet}/${questionSetId}`),
  saveInsightQuestionResponses: (payload: {
    questionSetId: string
    questionResponses: { questionId: string; answer: string }[]
  }) =>
    percentClient.post(`${apiPaths.v1InsightQuestionSet}/${payload.questionSetId}`, {
      questionResponses: payload.questionResponses
    })
})
