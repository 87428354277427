import { CausePortalPermission } from '@percent/cause-dashboard/services/iam/permission.types'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'

export const usePermissionCheck = () => {
  const authCtx = useAuthState()
  const userPermissions = authCtx.userPermissions || []

  const userCan = (permissions: CausePortalPermission[] | CausePortalPermission): boolean => {
    if (typeof permissions === 'string') {
      return userPermissions.includes(permissions)
    }

    return permissions.every(permission => userPermissions.includes(permission))
  }

  return { userCan }
}
