import { API_ERROR_MESSAGE } from '@percent/cause-dashboard/constants/apiErrorMessage'

// TODO: error code and error messages need to be update with the new api errors
export const APIErrorHandler = (error: any) => {
  if (error) {
    switch (error?.code) {
      case 'accounts/invalid':
      case 'forbidden':
      case 'auth/invalid_credentials_1':
      case 'auth/invalid_credentials_2':
      case 'auth/invalid_credentials_3':
      case 'auth/invalid_credentials_4':
      case 'auth/password_unsafe_1':
      case 'auth/password_unsafe_2':
      case 'auth/two_fa_invalid_recovery_code':
      case 'auth/two_fa_not_enabled':
        return error?.reasons?.[0] || error?.message
      case 'auth/two_fa_authentication_failed':
        return API_ERROR_MESSAGE.twoFaInvalidToken
      case 'auth/two_fa_invalid_otp_code':
        return API_ERROR_MESSAGE.twoFaInvalidTokenOtpCode
      case 'upload/max_file_size':
        return API_ERROR_MESSAGE.imageFileSize
      case 'auth/forbidden_password':
        return API_ERROR_MESSAGE.forbiddenPassword
      case 'auth/too_many_change_password_requests':
        return API_ERROR_MESSAGE.tooManyChangePasswordRequests
      case 'auth/link_expired':
        return API_ERROR_MESSAGE.linkExpired
      case 'rate_exceeded':
        return API_ERROR_MESSAGE.rateExceeded
      case 'auth/link_invalid':
        return API_ERROR_MESSAGE.linkInvalid
      case 'agent_verification_organisation_association/invalid_token':
        return API_ERROR_MESSAGE.tokenInvalid
      case 'not_found':
      default:
        return API_ERROR_MESSAGE.genericError
    }
  }

  return API_ERROR_MESSAGE.genericError
}
