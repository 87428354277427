import { Box, IconButton, Modal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styles from './PromotionalPopup.module.scss'
import { PromotionalPopupProps } from './PromotionalPopup.types'
import { MarketplaceBanner } from '@percent/cause-dashboard/app/productOffers/MarketPlaceBanner/MarketplaceBanner'
import { MarketplaceProgrammeVisitedSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

export function PromotionalPopup({ onClose, isOpened }: PromotionalPopupProps) {
  return (
    <Modal open={isOpened} data-testid="promo-popup">
      <Box className={styles.popupContentContainer}>
        <IconButton className={styles.closeButton} onClick={onClose} data-testid="promo-popup-close-button">
          <Close />
        </IconButton>
        <MarketplaceBanner
          forceMobileView={true}
          tracking={{
            source: MarketplaceProgrammeVisitedSource.Popup,
            programmeName: 'LinkedIn Fundraise'
          }}
        />
      </Box>
    </Modal>
  )
}
