import { Button, Grid, Typography } from '@material-ui/core'

import styles from './MarketplaceBanner.module.scss'
import bannerImage from '../../../assets/images/linkedin-promo.png'
import linkedInLogo from '../../../assets/images/LI-Logo-2.png'
import { useTranslation } from 'react-i18next'
import { MarketplaceBannerProps } from './MarketplaceBanner.types'
import { useMarketplaceAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useMarketplaceAnalytics'
import { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { ButtonText } from '@percent/lemonade'
import {
  MarketplaceProgrammeVisitedSource,
  PageViewSource,
  ProgrammeViewEventKind
} from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

const seeDetailsUrl = 'https://nonprofit.linkedin.com/online-discounts'
const getStartedUrl = 'https://validate.poweredbypercent.com/linkedin'

export function MarketplaceBanner({ tracking, forceMobileView }: MarketplaceBannerProps) {
  const [hasTrackedLoad, setHasTrackedLoad] = useState(false)
  const { t } = useTranslation()
  const { trackProgramVisited: track, trackProgramViewEvent } = useMarketplaceAnalytics()

  const trackProgramVisited = async (url: string) => {
    if (!tracking) {
      return
    }

    await track({
      source: tracking.source,
      targetUrl: url,
      programmeOfferName: tracking.programmeName
    })
  }

  const handleCTAClick = async () => {
    await trackProgramVisited(getStartedUrl)
    window.open(getStartedUrl, '_blank')
  }

  const handleDetailsClick = async () => {
    await trackProgramVisited(seeDetailsUrl)
    window.open(seeDetailsUrl, '_blank')
  }

  useEffect(() => {
    if (!tracking) {
      return
    }

    // We only want to track for banner because the popup handles its own tracking.
    if (tracking.source !== MarketplaceProgrammeVisitedSource.Banner) {
      return
    }

    if (hasTrackedLoad) {
      return
    }
    const trackViewOnFirstLoadOnly = async () =>
      await trackProgramViewEvent({
        eventKind: ProgrammeViewEventKind.BannerShown,
        programmeName: tracking.programmeName,
        page: PageViewSource.AppStore
      })

    trackViewOnFirstLoadOnly().then(r => setHasTrackedLoad(true))
  }, [hasTrackedLoad, tracking, trackProgramViewEvent])

  const cx = classNames.bind(styles)

  return (
    <Grid
      container
      className={cx({
        bannerContainer: true,
        bannerContainerMobileView: forceMobileView
      })}
    >
      <Grid sm={12} md={forceMobileView ? 12 : 8} item>
        <div
          className={cx({
            textContainer: true,
            textContainerMobileView: forceMobileView
          })}
        >
          <div className={styles.bannerLogs}>
            <div
              className={cx({
                spotlight: true,
                spotlightMobileView: forceMobileView
              })}
            >
              {t('promotionalBanner.spotlight')}
            </div>
            <img src={linkedInLogo} alt="LinkedIn logo" className={styles.linkedInLogo} />
          </div>
          <div>
            <Typography
              className={cx({
                promoText: true,
                promoTextMobileView: forceMobileView
              })}
            >
              {t(forceMobileView ? 'promotionalBanner.alternativeText1' : 'promotionalBanner.text1')}{' '}
              <span className={styles.boldedPromoText}>
                {t(forceMobileView ? 'promotionalBanner.alternativeText2' : 'promotionalBanner.text2')}
              </span>{' '}
              {forceMobileView ? '' : t('promotionalBanner.text3')}
            </Typography>
          </div>
          <div
            className={cx({
              buttonsContainer: true,
              buttonsContainerMobileView: forceMobileView
            })}
          >
            {' '}
            {forceMobileView && (
              <ButtonText onPress={handleDetailsClick}>{t('promotionalBanner.detailsButton')}</ButtonText>
            )}
            <Button className={styles.getStarted} onClick={handleCTAClick}>
              {t(forceMobileView ? 'productOffers.apply' : 'promotionalBanner.getStartedButton')}
            </Button>
          </div>
        </div>
      </Grid>
      <Grid sm={12} md={forceMobileView ? 12 : 4} item>
        <div
          className={cx({
            bannerImageContainer: true,
            bannerImageContainerMobileView: forceMobileView
          })}
        >
          <img src={bannerImage} className={styles.bannerImage} alt="Man with computer" />
        </div>
      </Grid>
    </Grid>
  )
}
