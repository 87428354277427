import { Box, Grid } from '@material-ui/core'

import { Badge } from '@percent/lemonade'
import { dayJS } from '@percent/cause-dashboard/common/utility/date'
import { useTranslation } from 'react-i18next'

import styles from '../../PayoutsPage.module.scss'
import { PayoutDetailsProps } from './PayoutDetails.types'
import { currencyMapToFormat } from '@percent/cause-dashboard/common/utility/money/currencyMap'

export function PayoutDetails({ data, currencyMap }: PayoutDetailsProps) {
  const { t } = useTranslation()

  const moneyFormat = currencyMapToFormat(currencyMap)

  return (
    <Box className={styles.detailsWrapper}>
      <Grid className={styles.detailsHeadersWrapper} container direction="row" wrap="nowrap">
        <Grid item>{t('typography.youReceived')}</Grid>
        <Grid item>{t('typography.status')}</Grid>
        <Grid item>{t('typography.payoutsTablePayoutDate')}</Grid>
      </Grid>
      <Grid className={styles.detailsInfoWrapper} container direction="row" wrap="nowrap">
        <Grid item data-testid="grossDonationValue">
          {data ? moneyFormat({ amount: data.paidAmount, currency: data.paidCurrencyCode }) : null}
        </Grid>
        <Grid item data-testid="statusValue">
          <Badge variant="positive" icon="approve">
            {t('typography.paid')}
          </Badge>
        </Grid>
        <Grid item data-testid="payoutDateValue">
          {data?.paidAt ? dayJS(data.paidAt).format('DD MMM YYYY') : null}
        </Grid>
      </Grid>
    </Box>
  )
}
