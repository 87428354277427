import { LocaleKey } from '@percent/cause-dashboard/i18n/config'
import { AgentVerificationStatus } from '@percent/cause-dashboard/services/agentVerification/AgentVerification.types'

export enum AVDocumentsStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
  PROCESSED = 'processed',
  EXPIRED = 'expired'
}

export type AgentVerificationDocumentsStatusProps = {
  status: AVDocumentsStatus
  agentVerificationStatus?: AgentVerificationStatus
  partnerName?: string
  email?: string
  organisationId?: string
}

export enum AVDocumentsPartner {
  GOOGLE = 'google',
  CLAIM = '[GS-INTERNAL] Causes Signup'
}

export type AgentVerificationDocumentsMessages = {
  [K in AVDocumentsStatus]: {
    title: LocaleKey
    description: LocaleKey
  } & (K extends AVDocumentsStatus.SUBMITTED ? { claimDescription: LocaleKey } : { claimDescription?: LocaleKey })
}
