import { Typography } from '@material-ui/core'
import React from 'react'

import { useTranslation } from 'react-i18next'
import styles from './ComingSoonTag.module.scss'

export function ComingSoonTag() {
  const { t } = useTranslation()

  return (
    <Typography className={styles.soon} variant="body2">
      {t('typography.soon')}
    </Typography>
  )
}
