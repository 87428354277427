import { Card, Grid } from '@material-ui/core'
import styles from './ClaimSearchHeader.module.scss'
import { ClaimSearchHeaderProps } from './ClaimSearchHeader.types'
import { useTranslation } from 'react-i18next'
import { ClaimSearchHeaderForm } from '../ClaimSearchHeaderForm/ClaimSearchHeaderForm'
import { useLocation } from 'react-router-dom'
import { Alert, Heading, Spacer } from '@percent/lemonade'

export function ClaimSearchHeader({ onSearchClick }: ClaimSearchHeaderProps) {
  const { t } = useTranslation()
  const { state } = useLocation()

  return (
    <Card className={styles.mainCard}>
      <Grid className={styles.headerTitle} container item xs={12}>
        <Spacer size={6} axis="vertical" />
        <Heading level="h6" align="left">
          {t('typography.claimYourOrganization')}
        </Heading>
        {state?.organizationNotFoundAlert && (
          <>
            <Spacer size={4} axis="vertical" />
            <Alert variant="error" title={t('typography.alertFindYourOrganization')} />
          </>
        )}
      </Grid>
      <ClaimSearchHeaderForm onSearchClick={onSearchClick} />
    </Card>
  )
}
