export type OrganisationData = {
  id: string
  inviterEmail: string
  organisationName: string
  logo?: string
  organisationWebsite?: string
}

export enum NextAction {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  SIGN_IN = 'SIGN_IN'
}

export type AcceptInviteResponse = {
  nextAction: NextAction
  data: OrganisationData
}

export type CreateAccountResponse = {
  accountId: string
  email: string
}
