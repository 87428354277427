import { IPasswordCriteriaDefinition } from '@percent/lemonade'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useDefaultPasswordCriteria = (): Record<string, IPasswordCriteriaDefinition> => {
  const { t } = useTranslation()

  const passwordCriteria = useMemo(
    () => ({
      uppercase: {
        evalFunction: (password: string) => /[A-Z]/.test(password),
        localisationKey: t('typography.passReqUppercaseLetter')
      },
      lowercase: {
        evalFunction: (password: string) => /[a-z]/.test(password),
        localisationKey: t('typography.passReqLowercaseLetter')
      },
      digit: {
        evalFunction: (password: string) => /[0-9]/.test(password),
        localisationKey: t('typography.passReqNumber')
      },
      specialCharacter: {
        evalFunction: (password: string) =>
          // eslint-disable-next-line
          /[ --*.!@#$%^&(){}[\]:;<>,.\\?\/~'"`_+=|]/.test(password),
        localisationKey: t('typography.passReqSpecialCharacter')
      },
      length: {
        evalFunction: (password: string) => password.length >= 8,
        localisationKey: t('typography.passReqLength')
      }
    }),
    [t]
  )

  return passwordCriteria
}
