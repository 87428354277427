import { useAnalyticsContext } from '@percent/cause-dashboard/context/analytics/AnalyticsContext/useAnalyticsContext'
import { SegmentCoreOptions } from '@percent/cause-dashboard/types'

export const useAnalytics = () => {
  const { analytics } = useAnalyticsContext()

  if (!analytics) return null

  const page = async (
    category: string,
    name: string,
    properties?: Record<string, unknown>,
    options?: { context: SegmentCoreOptions }
  ) => analytics?.page(category, name, properties, options)

  const identify = async (
    userId?: string,
    traits?: Record<string, unknown>,
    options?: { anonymousId?: string; context?: SegmentCoreOptions }
  ) => analytics?.identify(userId, traits, options)

  const track = async (name: string, properties: Record<string, unknown>, options?: { context?: SegmentCoreOptions }) =>
    analytics?.track(name, properties, options)

  return {
    page,
    identify,
    track,
    reset: analytics.reset
  }
}
