import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Copy } from '@percent/lemonade'
import { useDownloadStringFile } from '@percent/cause-dashboard/common/hooks/useDownloadStringFile/useDownloadStringFile'
import { ReactComponent as Download } from '@percent/cause-dashboard/common/assets/images/download.svg'
import styles from './SaveBackupCode.module.scss'
import { useTranslation } from 'react-i18next'
import { SaveBackupCodeStepProps } from './SaveBackupCode.types'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { SET_AUTHORISED } from '@percent/cause-dashboard/context/auth'

export const SaveBackupCode = ({ otpCode = '' }: SaveBackupCodeStepProps) => {
  const { authDispatch } = useAuthDispatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const downloadCode = useDownloadStringFile(otpCode, 'otp-backup.txt')

  const handleDoneClick = () => {
    push('/profile')
    authDispatch({
      type: SET_AUTHORISED,
      payload: {}
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.header}>{t('2fa.saveBackupCode')}</h1>
        <p className={styles.subtext}>{t('2fa.saveBackupCodeSubtext')}</p>
        <Button variant="secondary" disabled>
          {otpCode}
        </Button>
        <div className={styles.actionWrapper}>
          <div className={styles.copyWrapper}>
            <Copy text={otpCode} />
            <p className={styles.actionText}>{t('typography.copy')}</p>
          </div>
          <div className={styles.downloadWrapper}>
            <Download className={styles.download} onClick={downloadCode} />
            <p className={styles.actionText}>{t('typography.download')}</p>
          </div>
        </div>
        <div className={styles.separator} />
        <Button onPress={handleDoneClick} type="button">
          {t('button.done')}
        </Button>
      </div>
    </div>
  )
}
