import React from 'react'

import { capabilities } from '@percent/cause-dashboard/capabilities'
import { services } from '@percent/cause-dashboard/services'
import { ServiceContextController } from './ServiceContextController'

const appServices = services(capabilities)

type ServiceProviderProps = {
  children: React.ReactNode
}

export function ServiceContextProvider({ children }: ServiceProviderProps) {
  return <ServiceContextController services={appServices}>{children}</ServiceContextController>
}
