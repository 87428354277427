import { LocaleKey } from '@percent/cause-dashboard/i18n/config'
import { IconRegistry } from '@percent/lemonade'
import { ClaimStatusVariant } from './ClaimStatus.types'

export const claimStatusMap: {
  [key in string]: {
    variant: ClaimStatusVariant
    icon: keyof typeof IconRegistry
    text: LocaleKey
  }
} = {
  verified: {
    variant: 'positive',
    icon: 'check-badge',
    text: 'typography.verified'
  },
  rejected: {
    variant: 'critical',
    icon: 'reject',
    text: 'typography.rejected'
  },
  pending: {
    variant: 'default',
    icon: 'clock',
    text: 'typography.notVerified'
  }
}
