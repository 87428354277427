import { Divider, Typography } from '@material-ui/core'

import styles from './EmailFooter.module.scss'
import { config } from '@percent/cause-dashboard/config'
import { useTranslation } from 'react-i18next'

export function EmailFooter() {
  const { t: loco } = useTranslation('causeDashboardLoco')

  return (
    <footer className={styles.footer}>
      <Divider />
      <Typography variant="body2" className={styles.typography}>
        <a target="_blank" rel="noreferrer" href={config.urls.poweredByPercent} className={styles.poweredByPercent}>
          {loco('agent_verification_confirmation.success_page.footer.copyright', {
            currentYear: new Date().getFullYear(),
            CompanyTradingName: 'We Are Percent Ltd'
          })}
        </a>
        &nbsp;
        <span className={styles.termsOfUse}>
          <a target="_blank" rel="noreferrer" href={config.urls.terms}>
            {loco('agent_verification_confirmation.success_page.footer.terms_privacy_link')}
          </a>
        </span>
      </Typography>
    </footer>
  )
}
