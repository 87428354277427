import i18n from 'i18next'
import { initReactI18next, DefaultResources } from 'react-i18next'

import { translationResources } from './resources'

i18n.use(initReactI18next).init({
  resources: translationResources,
  lng: 'en-US',
  fallbackLng: 'en-US',
  ns: ['causeDashboard', 'causeDashboardLoco'],
  defaultNS: 'causeDashboard',
  interpolation: {
    escapeValue: false,
    prefix: '{',
    suffix: '}'
  },
  returnEmptyString: false
})

export default i18n

export type LocaleKey = keyof DefaultResources['causeDashboard']
