import { SitewideMessageContext } from '@percent/cause-dashboard/context/sitewideMessage/sitewideMessageContext/SitewideMessageContext'
import { useContext } from 'react'

export const useSitewideMessage = () => {
  const context = useContext(SitewideMessageContext)

  if (context === undefined) {
    throw new Error('useSitewideMessage must be used within a SitewideMessageContextController')
  }

  return context
}
