import { useEffect, useMemo, useState } from 'react'
import { PromotionalPopupContext } from '../promotionalPopupContext/PromotionalPopupContext'
import { PromotionalPopupContextControllerProps } from './PromotionalPopupContextController.types'
import { PromotionalPopup } from '@percent/cause-dashboard/common/components/promotionalPopup/PromotionalPopup'
import { useHistory } from 'react-router-dom'
import { Location } from 'history'
import debounce from 'lodash/debounce'
import { getPopupData, isInAllowedTimeRange, isRouteEnabled, updatePopupData } from './PromotionalPopup.utility'
import { useMarketplaceAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useMarketplaceAnalytics'
import {
  PageViewSource,
  ProgrammeViewEventKind
} from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { useAuthState } from '@percent/cause-dashboard/common/hooks/useAuthState/useAuthState'

const tracking = {
  programmeName: 'LinkedIn Fundraise'
}

export function PromotionalPopupContextController({ children }: PromotionalPopupContextControllerProps) {
  const history = useHistory()
  const [isOpened, setIsOpened] = useState(false)
  const {
    authState: { isAuthorised }
  } = useAuthState()

  const { trackProgramViewEvent } = useMarketplaceAnalytics()

  useEffect(() => {
    const unlisten = history.listen(
      debounce(async (location: Location) => {
        const popupInfo = getPopupData()
        const shouldOpen = isRouteEnabled(location.pathname) && isInAllowedTimeRange(popupInfo) && isAuthorised

        setIsOpened(shouldOpen)
        if (shouldOpen) {
          updatePopupData(popupInfo)
          await trackProgramViewEvent({
            eventKind: ProgrammeViewEventKind.PopupShown,
            programmeName: tracking.programmeName,
            page: PageViewSource.Profile
          })
        }
      }),
      500
    )

    return () => {
      unlisten()
    }
  }, [history, isAuthorised])

  const isOpenMemo = useMemo(() => ({ isOpened }), [isOpened])

  const onClose = async () => {
    setIsOpened(false)
    await trackProgramViewEvent({
      eventKind: ProgrammeViewEventKind.PopupDismissed,
      programmeName: tracking.programmeName,
      page: PageViewSource.Profile
    })
  }

  return (
    <PromotionalPopupContext.Provider value={isOpenMemo}>
      <PromotionalPopup onClose={onClose} isOpened={isOpened} />
      {children}
    </PromotionalPopupContext.Provider>
  )
}
