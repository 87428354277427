import { useCallback, useEffect, useState } from 'react'

export interface IPasswordCriteriaDefinition {
  evalFunction: (password: string) => boolean
  localisationKey: string
}

export const usePasswordCriteria = <
  T extends Record<keyof T, IPasswordCriteriaDefinition>
>(
  criteria: T,
  password: string
) => {
  const [passwordCriteriaBreakdown, setPasswordCriteriaBreakdown] = useState<
    {
      name: keyof T
      isValid: boolean
      message: string
    }[]
  >([])

  const evaluatePassword = (password: string) => {
    const criteriaKeys = Object.keys(criteria)
    return criteriaKeys.every((key) =>
      criteria[key as keyof T].evalFunction(password)
    )
  }

  const getPasswordCriteriaBreakdown = useCallback(() => {
    const criteriaKeys = Object.keys(criteria)
    return criteriaKeys.map((key) => {
      const criteriaForThisConstraint = criteria[key as keyof T]
      return {
        name: key as keyof T,
        isValid: criteriaForThisConstraint.evalFunction(password),
        message: criteriaForThisConstraint.localisationKey,
      }
    })
  }, [criteria, password])

  useEffect(() => {
    setPasswordCriteriaBreakdown(getPasswordCriteriaBreakdown())
  }, [getPasswordCriteriaBreakdown])

  return {
    evaluatePassword,
    passwordCriteriaBreakdown,
    isValid: evaluatePassword(password),
  }
}
