import React from 'react'
import { Grid, Typography } from '@material-ui/core'

//TODO: will be replaced by generic error page
export function Fallback() {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
      <Typography align="center">Sorry, an unknown error occurred, please contact us to see if we can help.</Typography>
    </Grid>
  )
}
