import { CentralCardLayoutProps } from 'libs/domain/iam/src/components/CentralCardLayout/CentralCardLayout.types'
import { useSitewideMessage } from '../../hooks/useSitewideMessage/useSitewideMessage'
import { CentralCardLayout } from '@percent/domain/iam'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { CausesPortalLogo } from '../causesPortalLogo/CausesPortalLogo'

export function CentralCardLayoutWrapper(props: Readonly<CentralCardLayoutProps>) {
  const { shouldShowSitewideMessage } = useSitewideMessage()

  return (
    <CentralCardLayout
      {...props}
      footer={<Footer />}
      isRebrandBanner={shouldShowSitewideMessage}
      logoHref={<CausesPortalLogo isCardLayout />}
    />
  )
}
