import { AxiosInstance } from 'axios'

import { getDataFromPBPResponse } from '@percent/cause-dashboard/common/utility/pbpApi'
import { Claim } from './claimService.types'
import { apiPaths } from '@percent/cause-dashboard/services/apiPaths'
import { PBPListResponse, PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'

type causeServiceCapabilities = {
  percentClient: AxiosInstance
}

export const claimService = ({ percentClient }: causeServiceCapabilities) => ({
  getClaims: () => percentClient.get<PBPListResponse<Claim>>(`${apiPaths.v1Claims}`),
  postVSClaim: ({ organisationId }: { organisationId: string }) =>
    percentClient.post<PBPResponse<Claim>>(`${apiPaths.v1VSClaims}`, { organisationId }).then(getDataFromPBPResponse)
})
