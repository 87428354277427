import React from 'react'
import { FormControl } from '@material-ui/core'

import { FieldError } from '../fieldError/FieldError'

import styles from './FieldInputCurrency.module.scss'
import { FieldInputCurrencyProps } from './FieldInputCurrency.types'

export function FieldInputCurrency({
  value,
  label,
  error,
  onChange,
  placeHolder,
  addOn,
  handleBlur,
  name,
  className,
  touched,
  isRequiredField
}: FieldInputCurrencyProps) {
  const errorMsg = touched && error ? error : undefined

  return (
    <FormControl className={`${className || ''} ${styles.field}`}>
      <label className={styles.fieldLabel}>
        {label} {isRequiredField && <span> *</span>}
      </label>

      <span after-content={addOn} className={`${addOn ? styles.currency : ''} ${styles.addOnDefault}`}>
        <input
          id={label}
          name={name}
          value={value}
          placeholder={placeHolder || label}
          onBlur={handleBlur}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          type="number"
          min="0.00"
          max="15000000.00"
          step=".01"
          pattern="\d+(\.\d{1,2})?"
          required
          onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
          }
          data-testid={name}
        />
      </span>
      <FieldError error={errorMsg} />
    </FormControl>
  )
}
