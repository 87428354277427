import React from 'react'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Button, FormField, Spacer, TextInput, Modal, ButtonText } from '@percent/lemonade'
import { ChangeTwoFactorDeviceProps } from './ChangeTwoFactorDevice.types'
import styles from './ChangeTwoFactorDevice.module.scss'
import { useTranslation } from 'react-i18next'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components/centralCardLayoutWrapper/centralCardLayoutWrapper'

export function ChangeTwoFactorDevice({
  isOpened,
  onClose,
  errorMessage,
  isLoading,
  generateQRToken
}: ChangeTwoFactorDeviceProps) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: yup.object().shape({
      password: yup.string().min(8).max(64).required('Required')
    }),
    onSubmit: values => {
      const { password } = values
      generateQRToken({ password })
    }
  })

  const { errors, values, touched, dirty, isValid, handleChange, handleBlur, handleSubmit } = formik

  return (
    <Modal open={isOpened} onClose={onClose} isFullscreen whiteBg={false}>
      <CentralCardLayoutWrapper heading={t('2fa.enable2fa')} handleSubmit={handleSubmit} error={errorMessage}>
        <div className={styles.passwordContainer}>
          <Typography align="left">{t('2fa.enable2faTitle')}</Typography>
          <FormikProvider value={formik}>
            <FormField
              label={t('2fa.passwordLabel')}
              status={touched.password && errors.password ? 'danger' : 'default'}
              statusMessage={errors.password}
              data-testid="password"
              necessity="required"
            >
              <TextInput
                type="password"
                name="password"
                placeholder={t('2fa.passwordPlaceholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </FormField>
          </FormikProvider>
          <Spacer size={6} axis="vertical" />
          <Button disabled={!(isValid && dirty)} stretch loading={isLoading} type="submit">
            {t('button.setUp')}
          </Button>
          <Spacer size={6} axis="vertical" />
          <div className={styles.cancelButtonWrapper}>
            <ButtonText onPressEnd={onClose}>{t('button.cancelSetup')}</ButtonText>
          </div>
        </div>
      </CentralCardLayoutWrapper>
    </Modal>
  )
}
