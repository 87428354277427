import { FormatAmountProps } from './formatAmount.types'

export const formatAmount = ({ currencyCode, value, language = 'en-us' }: FormatAmountProps) =>
  new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCode || 'USD' // some my nonprofits organisation have currencyCode: null
  }).format(value)

export const formatMinorUnitsValueToFullNumberWithDecimals = (minorUnitsValue: number, minorUnits: number): number => {
  return minorUnitsValue / 10 ** minorUnits
}
