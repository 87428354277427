import { dayJS } from './date'

// TODO: [Tech Debt] -> remove this and use content disposition everywhere
export const csvFileName = (startDate?: string | null, endDate?: string | null): string => {
  const dateFormat = 'YYYY-MM-DD_hh.mm.ss'
  const start = startDate ? dayJS(startDate).utc().format(dateFormat) : null
  const end = endDate ? dayJS(endDate).utc().format(dateFormat) : dayJS().utc().format(dateFormat)

  return `donations_export${start ? '_' + start : ''}_end_${end}_timezone_GMT.csv`
}
