import { Logo } from '@percent/lemonade'
import styles from './CausesPortalLogo.module.scss'
import { GOODSTACK_WEBSITE } from '@percent/cause-dashboard/constants/general'
import classNames from 'classnames/bind'

type CausesPortalLogoProps = Readonly<{
  isCardLayout?: boolean
}>

export function CausesPortalLogo({ isCardLayout = false }: CausesPortalLogoProps) {
  return (
    <a
      className={classNames({
        [styles.causesPortalLogoCentralCard]: isCardLayout,
        [styles.causesPortalLogo]: !isCardLayout
      })}
      href={GOODSTACK_WEBSITE}
    >
      <Logo />
    </a>
  )
}
