import { useState } from 'react'

import { APIErrorHandler } from '@percent/cause-dashboard/common/library/APIErrorHandler'
import { PercentAPIError } from '@percent/cause-dashboard/common/hooks/http.types'

export function useMutation<A, B>(api: (_: A) => Promise<B>, onSuccess?: (_: B) => void, onError?: (_: Error) => void) {
  const [data, setData] = useState<B | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<PercentAPIError | null>()
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)

  const apiFunc = (payload: A) => {
    let didCancel = false

    setIsLoading(true)
    setSuccess(false)

    const callApi = async () => {
      try {
        const result = await api(payload)
        setSuccess(true)

        if (!didCancel) {
          setIsLoading(false)
          setData(result)
          setIsLoading(false)
          setErrorMessage('')
        }

        if (onSuccess) {
          await onSuccess(result)
        }
      } catch (err: any) {
        if (!didCancel) {
          setErrorMessage(APIErrorHandler((err?.response?.data?.error ?? err?.request?.response?.error) as any))
          setError(err?.response?.data?.error ?? err?.request?.response.error)
          setIsLoading(false)
        }

        if (onError) {
          onError(err)
        }
      }
    }
    callApi()

    return () => {
      didCancel = true
    }
  }

  return [
    {
      data,
      isLoading,
      error,
      errorMessage,
      success
    },
    {
      setErrorMessage,
      setError,
      apiFunc
    }
  ] as const
}
