import {
  IPasswordCriteriaDefinition,
  usePasswordCriteria,
} from './hooks/usePasswordCriteria'
import { Check } from '@material-ui/icons'
import styled from 'styled-components'

interface PasswordCriteriaProps {
  password: string
  passwordCriteria: Record<string, IPasswordCriteriaDefinition>
  visible?: boolean
}

const StyledPasswordCriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  text-align: left;

  background-color: ${({ theme }) => theme.colors.gray10};
`

const StyledPasswordCriteria = styled.li`
  font-size: ${({ theme }) => theme.fontSizes.bodySmall};
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  text-align: left;
`

const StyledPasswordCriteriaCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.gray10};
`

const StyledPasswordCriteriaIconNegative = styled.div`
  color: ${({ theme }) => theme.colors.gray100};
`
const StyledPasswordCriteriaIconPositive = styled.div`
  color: ${({ theme }) => theme.colors.green40};
`

export const PasswordCriteria = ({
  password,
  visible,
  passwordCriteria,
}: PasswordCriteriaProps) => {
  const { passwordCriteriaBreakdown } = usePasswordCriteria(
    passwordCriteria,
    password
  )

  return (
    <StyledPasswordCriteriaCard
      style={{
        maxHeight: visible ? '500px' : 0,
        opacity: visible ? 1 : 0,
        visibility: visible ? 'visible' : 'hidden',
        transition: visible
          ? 'opacity 0.4s ease-in-out, max-height 0.5s ease-in-out, visibility 0s linear 0s'
          : 'opacity 0.4s ease-in-out, max-height 0.5s ease-in-out, visibility 0s linear 0.5s',
      }}
      data-testid="password-criteria"
    >
      <StyledPasswordCriteriaContainer>
        {passwordCriteriaBreakdown.map((criteria, index) => {
          return (
            <StyledPasswordCriteria key={criteria.name}>
              <div style={{ color: 'green' }}>
                {criteria.isValid ? (
                  <StyledPasswordCriteriaIconPositive
                    data-testid={`${criteria.name}-pass`}
                  >
                    <Check />
                  </StyledPasswordCriteriaIconPositive>
                ) : (
                  <StyledPasswordCriteriaIconNegative
                    data-testid={`${criteria.name}-fail`}
                  >
                    <Check />
                  </StyledPasswordCriteriaIconNegative>
                )}
              </div>
              {criteria.message}
            </StyledPasswordCriteria>
          )
        })}
      </StyledPasswordCriteriaContainer>
    </StyledPasswordCriteriaCard>
  )
}
