import { config } from '@percent/cause-dashboard/config'
import { apiPaths } from '@percent/cause-dashboard/services/apiPaths'
import {
  CreateMilestonesItemProps,
  PatchMilestonesItemProps,
  MilestonesItem,
  MilestonesCapabilities,
  GetMilestonesProps,
  DeleteMilestonesItemProps
} from './milestonesService.types'
import { PBPListResponse, PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'

export const milestonesService = ({ percentClient }: MilestonesCapabilities) => ({
  getMoneyMilestones: (id: string) => (params: GetMilestonesProps) =>
    percentClient.get<PBPListResponse<MilestonesItem>>(`${apiPaths.v1Cause}${id}/milestones`, {
      params,
      headers: {
        Authorization: config.keys.pk
      },
      withCredentials: false
    }),
  createMilestonesItem: ({ id, ...postParams }: CreateMilestonesItemProps) =>
    percentClient.post<PBPResponse<MilestonesItem>>(`${apiPaths.v1Cause}${id}/milestones`, postParams, {
      headers: { Authorization: config.keys.pk }
    }),
  patchMilestonesItem: ({ organisationId, id, ...patchParams }: PatchMilestonesItemProps) =>
    percentClient.patch<PBPResponse<MilestonesItem>>(
      `${apiPaths.v1Cause}${organisationId}/milestones/${id}`,
      patchParams,
      {
        headers: { Authorization: config.keys.pk }
      }
    ),
  deleteMilestonesItem: ({ organisationId, id }: DeleteMilestonesItemProps) =>
    percentClient.delete<PBPResponse<MilestonesItem>>(`${apiPaths.v1Cause}${organisationId}/milestones/${id}`, {
      headers: { Authorization: config.keys.pk }
    })
})
