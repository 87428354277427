export const apiPaths = {
  v1Partner: 'v1/partner/',
  v1Admin: 'v1/admin/',
  v1Auth: 'v1/auth/',
  v1Cause: 'v1/organisations/',
  v1Claims: 'v1/claims',
  v1VSClaims: 'v1/iam/claim',
  v1VSOrganization: 'v1/iam/signup-new-organisation',
  v1Account: 'v1/account/',
  v1Countries: 'v1/countries/',
  v1AgentVerification: 'v1/agent-verifications/',
  v1AgentVerificationUserVerification: 'v1/agent-verification-user-verification',
  v1Currencies: 'v1/currencies',
  v1IamAccounts: '/v1/iam/accounts',
  v1IamAccountsRolesAttach: '/iam/accounts/roles/attach',
  v1IamAccountsInvite: '/v1/iam/invite',
  v1IamPermissions: '/v1/iam/permissions',
  v1IamRoles: '/v1/iam/roles',
  v1StoreApplications: '/v1/store/applications',
  v1AccountMarketingConsent: 'v1/account/legal/marketing-consent-for-account',
  v1CauseUsers: 'v1/causes/users',
  v1InsightQuestionSet: 'v1/causes/insights'
}

export const baseUrl = 'v1/'
