import { AxiosInstance } from 'axios'

import { baseUrl } from '@percent/cause-dashboard/services/apiPaths'
import { PBPListResponse, PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { Payout, GetPayoutsListProps, DonationWithFees } from './payoutsService.types'

type payoutsServiceCapabilities = {
  percentClient: AxiosInstance
}

export const payoutsService = ({ percentClient }: payoutsServiceCapabilities) => ({
  getPayoutsList: (organisationId: string) => (params: GetPayoutsListProps) =>
    percentClient.get<PBPListResponse<Payout>>(`${baseUrl}organisations/${organisationId}/disbursements`, {
      params
    }),
  getPayoutDetails: ({ organisationId, disbursementId }: { organisationId: string; disbursementId: string }) =>
    percentClient.get<PBPResponse<Payout>>(`${baseUrl}organisations/${organisationId}/disbursements/${disbursementId}`),
  getPayoutDonationsIncludedList: (organisationId: string, disbursementId: string) => (params: GetPayoutsListProps) =>
    percentClient.get<PBPListResponse<DonationWithFees>>(
      `${baseUrl}organisations/${organisationId}/disbursements/${disbursementId}/donations`,
      { params }
    )
})
