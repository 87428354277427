import { AxiosInstance } from 'axios'

import { getDataFromPBPResponse } from '@percent/cause-dashboard/common/utility/pbpApi'
import { AccountOrganisation } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'
import { apiPaths } from '@percent/cause-dashboard/services/apiPaths'
import { PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { Account, AccountMarketingConsent, SetMarketingConsentProps, UpdateAccountProps } from './accountService.types'

type accountServiceCapabilities = {
  percentClient: AxiosInstance
}

export const accountService = ({ percentClient }: accountServiceCapabilities) => ({
  getAccount: () => percentClient.get<PBPResponse<Account>>(apiPaths.v1Account).then(getDataFromPBPResponse),
  getAccountOrganisation: () =>
    percentClient.get<PBPResponse<AccountOrganisation>>(`${apiPaths.v1Account}organisations`),
  updateAccount: (payload: UpdateAccountProps) =>
    percentClient.patch<PBPResponse<Account>>(apiPaths.v1Account, payload),
  setMarketingConsent: (payload: SetMarketingConsentProps) =>
    percentClient.post<PBPResponse<Array<AccountMarketingConsent>>>(apiPaths.v1AccountMarketingConsent, payload),
  getMarketingConsents: () =>
    percentClient.get<PBPResponse<Array<AccountMarketingConsent>>>(apiPaths.v1AccountMarketingConsent)
})
