import { Dialog, Grid } from '@material-ui/core'
import React from 'react'

import { ModalContainerProps } from './Modal.types'
import styles from './ModalContainer.module.scss'
import { ModalHeader } from './Modal/ModalHeader'

export function ModalContainer({ openModal, onClose, headerTitle, children, fullscreen }: ModalContainerProps) {
  return (
    <Grid container spacing={4} className={styles.dialogGrid}>
      <Dialog
        open={openModal}
        onClose={onClose}
        fullWidth
        className={styles.dialogContainer}
        maxWidth={fullscreen ? 'lg' : 'sm'}
      >
        {headerTitle && <ModalHeader onClose={onClose} headerTitle={headerTitle} />}
        <span className={styles.divider}></span>
        {children}
      </Dialog>
    </Grid>
  )
}
