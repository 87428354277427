import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'

import { LocationState } from '../TwoFactorAuthentication.types'

import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { SetupQRStep } from './SetupQRStep'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'

export function SetupQRStepContainer() {
  const { authService } = useServices()
  const { state } = useLocation<LocationState>()
  const { push } = useHistory()

  if (!state?.otpData?.otpKey) {
    push('/profile')
  }

  const [{ isLoading, data }, { apiFunc: generateQRToken }] = useMutation(authService.generateQRCode)
  useEffect(() => {
    if (!state?.otpData?.otpKey) generateQRToken({ password: state?.password })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SetupQRStep
      isLoading={isLoading}
      token={state?.otpData?.otpKey ?? data?.data?.data?.otpKey}
      tokenUri={state?.otpData?.otpKeyUri ?? data?.data?.data?.otpKeyUri}
    />
  )
}
