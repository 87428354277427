import { AuthAction } from './authReducer.types'
import { AuthStateType } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'

export const SET_AUTHORISED = 'auth/set-authorised'
export const SET_UNAUTHORISED = 'auth/set-unauthorised'
export const START_AUTHORISING = 'auth/start-authorising'
export const SET_LOGOUT = 'auth/set-logout'
export const SET_EMAIL = 'auth/set-email'
export const SET_ORGANISATION_DETAIL = 'auth/set-organisation-detail'
export const SET_USER_DETAIL = 'auth/set-user-detail'
export const SET_USER_VERIFIED = 'auth/set-user-verified'
export const SET_VERIFY = 'auth/set-verify'
export const SET_SESSION_EXPIRED = 'auth/session-expired'

export const initialState = {
  isAuthorised: false,
  isAuthorising: true,
  sessionExpired: false,
  user: undefined,
  organisation: undefined,
  claimOrganisation: undefined,
  orgBankDetails: undefined
}

export const initializer = (initialValue = initialState) =>
  JSON.parse(localStorage.getItem('authState')!) || initialValue

export const authReducer: (state: AuthStateType, action: AuthAction) => AuthStateType = (state, action) => {
  switch (action.type) {
    case START_AUTHORISING:
      return {
        ...state,
        isAuthorising: true
      }
    case SET_AUTHORISED:
      return {
        ...state,
        ...action.payload,
        isAuthorising: false,
        isAuthorised: true,
        sessionExpired: false
      }
    case SET_UNAUTHORISED:
    case SET_LOGOUT:
    case SET_VERIFY:
      return {
        ...initialState,
        isAuthorising: false,
        isAuthorised: false
      }
    case SET_EMAIL:
    case SET_USER_DETAIL:
    case SET_ORGANISATION_DETAIL:
      return {
        ...state,
        ...action.payload
      }
    case SET_USER_VERIFIED:
      return {
        ...state,
        user: {
          ...state.user,
          verifiedAt: new Date().toISOString()
        }
      }
    case SET_SESSION_EXPIRED:
      return {
        ...initialState,
        sessionExpired: true
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export type AuthState = {
  loggedIn: boolean
  userVerified: boolean
  claimMade: boolean
  claimAccepted: boolean
  rejectedBankDetails: boolean
  sessionExpired: boolean
}

export const selectAuthState = (authState: AuthStateType): AuthState => ({
  loggedIn: !!authState.isAuthorised,
  userVerified: !!authState.user?.verifiedAt,
  claimMade: !!authState.organisation || !!authState.claimOrganisation,
  claimAccepted: !!authState.user?.organisationId,
  rejectedBankDetails: !!(authState.orgBankDetails?.status === 'rejected'),
  sessionExpired: !!authState.sessionExpired
})
