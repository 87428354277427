import { ExperimentsConfig } from '@percent/cause-dashboard/common/hooks/useExperiment/experiments.types'

const defaultExcludedPartners = [
  'partner_000000CMTqjAZ1lf21cvt6GyJnUI4', // Canva
  'partner_000000CKEn2WDB5hIbtfLjLCGZ6a8', // Google
  'partner_000000CjsIujTyTOUojcpDuaI5Su8', // Microsoft
  'partner_000000CJrvH858NhS0dQDWWNI6nTc', // Monday.com
  'partner_000000ChDQI2W3Zpa7RJ2DQnDWolc', // Twilio
  'partner_000000CkrkkKUg4D2mULQt9FsbYUS' // OpenAI
]

const eventNames = ['logPageView', 'clickCta'] as const
export enum TilExperiment {
  SignUpToTrackVerificationStatus = 'signUpToTrackVerificationStatus',
  FastTrackGoogleApplication = 'fastTrackGoogleApplication'
}

export const experimentsConfig: ExperimentsConfig<TilExperiment, typeof eventNames[number]> = {
  signUpToTrackVerificationStatus: {
    // Note: This feature flag value should be the key of the feature in
    // the Split SDK provider.
    name: 'Sign up to track verification status',
    events: {
      logPageView: {
        name: 'Track verification status CTA: AV Page View'
      },
      clickCta: {
        name: 'Track verification status CTA: AV CTA Click'
      }
    },
    partnerIdCondition: { type: 'exclude', ids: defaultExcludedPartners }
  },
  fastTrackGoogleApplication: {
    name: 'Book a call to fast track your Google application',
    featureFlag: 'experimentFastTrackGoogleApplication',
    events: {
      logPageView: {
        name: 'Signup Experiment: Google AV Page view'
      },
      clickCta: {
        name: 'Signup Experiment: Fast Track Google Application CTA click'
      }
    },
    partnerIdCondition: { type: 'include', ids: ['partner_000000CKEn2WDB5hIbtfLjLCGZ6a8'] } // Note: Google's ID
  }
}

export type ExperimentConfigName = keyof typeof experimentsConfig

export const teamIcedLatteExperiments = experimentsConfig
