import { useEffect, useState } from 'react'

import { CountriesProviderProps } from './CountriesProvider.types'
import { Countries } from '@percent/cause-dashboard/services/countries/countriesService.types'
import { useServices } from '../../serviceContext/ServiceContext'
import { logger } from '@percent/cause-dashboard/common/utility/logger'
import { ValidationError } from 'yup'
import { CountriesContext } from '../countriesContext/CountriesContext'

export function CountriesProvider({ children }: CountriesProviderProps) {
  const [countries, setCountries] = useState<Countries[]>([])
  const { countriesService } = useServices()

  useEffect(() => {
    countriesService
      .getCountries()
      .then(res => {
        setCountries(res)
      })
      .catch(e => {
        logger(e as ValidationError)
      })
  }, [countriesService])

  return <CountriesContext.Provider value={countries}>{children}</CountriesContext.Provider>
}
