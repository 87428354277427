import { useEffect, useState } from 'react'
import { ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { AcknowledgeModal, Modal } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { PERCENT_SERVICE_CALENDLY_URL_TILES } from '@percent/cause-dashboard/app/productOffers/data/appStoreUrls'
import { useMarketplaceAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useMarketplaceAnalytics'
import { ProgramGenericEventKind } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { AppStoreSuccessModalProps } from './AppStoreSuccessModal.types'

const AppStoreSuccessModal = ({ offerType, open, onClose, trackingData }: AppStoreSuccessModalProps) => {
  const { t } = useTranslation()
  const { trackProgramEvent } = useMarketplaceAnalytics()
  const [hasTracked, setHasTracked] = useState(false)

  useEffect(() => {
    if (hasTracked) return

    trackProgramEvent({
      programmeName: trackingData.offerName,
      eventKind: ProgramGenericEventKind.Viewed,
      source: 'AppStoreSuccessModal',
      metadata: {
        offerName: trackingData.offerName,
        originCategory: trackingData.categoryName,
        offerType,
        hasBuyNowOption: trackingData.hasBuyNowOption
      }
    })?.then(() => setHasTracked(true))
  }, [trackProgramEvent, trackingData, hasTracked, setHasTracked])

  const handleBookACallButtonClick = () => {
    onClose()
    window.open(PERCENT_SERVICE_CALENDLY_URL_TILES, '_blank', 'noopener')

    trackProgramEvent({
      programmeName: trackingData.offerName,
      eventKind: ProgramGenericEventKind.ButtonClicked,
      source: 'AppStoreSuccessModal',
      metadata: {
        offerName: trackingData.offerName,
        originCategory: trackingData.categoryName,
        offerType,
        hasBuyNowOption: trackingData.hasBuyNowOption
      }
    })
  }

  return offerType === ProductOfferType.PartnerProduct ? (
    <Modal open={open}>
      <AcknowledgeModal
        title={t('typography.marketplaceSuccessTitle')}
        handleClose={onClose}
        result={'positive'}
        buttonText={t('typography.continueBrowsing')}
      >
        {t('typography.marketplaceSuccessDesc')}
      </AcknowledgeModal>
    </Modal>
  ) : (
    <Modal open={open}>
      <AcknowledgeModal
        title={t('typography.marketplaceBookACallTitle')}
        handleClose={handleBookACallButtonClick}
        result={'positive'}
        buttonText={t('typography.marketplaceBookACallCta')}
        secondaryButtonText={t('typography.continueBrowsing')}
        onSecondaryButtonClick={onClose}
      />
    </Modal>
  )
}

export default AppStoreSuccessModal
