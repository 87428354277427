export enum SignUpEventName {
  SEARCH_FOR_NONPROFIT = 'Search for non profit',
  SEARCH_RESULTS = 'Search results',
  CLAIM_NONPROFIT = 'Claim non profit click',
  SIGNUP_AND_CLAIM = 'Sign up and claim click',
  MULTI_STEP_FORM_STEP_1 = 'Multi step form step 1 completed',
  MULTI_STEP_FORM_STEP_2 = 'Multi step form step 2 completed',
  SIGNUP_COMPLETED = 'Sign up completed',
  ORG_ALREADY_CLAIMED_ERROR = 'Org already claimed error'
}
