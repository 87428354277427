import React from 'react'

import { Services } from '@percent/cause-dashboard/services/index'
import { ServiceContext } from './ServiceContext'

type ServiceContextControllerProps = {
  children: React.ReactNode
  services: Services
}

export function ServiceContextController({ children, services }: ServiceContextControllerProps) {
  return <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
}
