import { useMemo } from 'react'
import { AnalyticsContext } from '../AnalyticsContext/AnalyticsContext'

import { AnalyticsContextControllerProps } from './AnalyticsContextController.types'

export const AnalyticsContextController = ({
  writeKey,
  logger,
  options,
  children
}: AnalyticsContextControllerProps) => {
  const value = useMemo(() => {
    try {
      if (!writeKey) return {}

      window?.analytics.load(writeKey, options)
      return { analytics: window?.analytics }
    } catch (error) {
      logger(error)
      return {}
    }
  }, [writeKey, options])

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>
}
