import { useState } from 'react'

const cacheKeys = ['country', 'signUpAttribution'] as const

export type CacheKey = typeof cacheKeys[number]

type CacheSchema = {
  value: string
  timestamp: number
  expiresAt: number
}

export interface IStoreInCacheOpts {
  expiresInHours?: number
}

export const useLocalUserPreference = (
  cacheKey: CacheKey
): [string | null, (newValue: string, opts?: IStoreInCacheOpts) => void] => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setStateValue] = useState('')
  const cacheId = `preference_${cacheKey}`

  const storeInCache = (newValue: string, opts?: IStoreInCacheOpts) => {
    const storeTime = new Date().getTime()
    const objToStore: CacheSchema = {
      value: newValue,
      timestamp: storeTime,
      expiresAt: opts?.expiresInHours ? storeTime + opts?.expiresInHours * 1000 * 60 * 60 : -1
    }

    localStorage.setItem(cacheId, JSON.stringify(objToStore))
    setStateValue(newValue)
  }

  const getValue = () => {
    const stored = localStorage.getItem(cacheId)

    if (!stored) {
      return null
    }

    try {
      const parsed = stored ? JSON.parse(stored) : null
      const now = new Date().getTime()
      const isExpired = parsed.expiresAt !== -1 && now > parsed.expiresAt

      if (isExpired) {
        localStorage.removeItem(cacheId)
        return null
      }

      return parsed.value as string
    } catch {
      return null
    }
  }

  return [getValue(), storeInCache]
}
