import { Button, DialogTitle, Grid } from '@material-ui/core'
import React from 'react'

import { ModalHeaderProps } from '../Modal.types'
import styles from '../ModalContainer.module.scss'

import { ReactComponent as CloseLogo } from '@percent/cause-dashboard/common/assets/images/close.svg'

export function ModalHeader({ headerTitle, onClose }: ModalHeaderProps) {
  return (
    <Grid item xs={12} id={styles.dialogHeader}>
      <DialogTitle id={styles.dialogTitle}>{headerTitle}</DialogTitle>
      <Button id={styles.btnCloseDialog} onClick={onClose} type="button" data-testid="close-button">
        <CloseLogo />
      </Button>
    </Grid>
  )
}
