import { getDataFromPBPResponse } from '@percent/cause-dashboard/common/utility/pbpApi'
import { PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { BankDetail, BankDetailsServiceCapabilities, PostBankDetailsProps } from './bankDetails.types'

export const bankDetailsService = ({ percentClient }: BankDetailsServiceCapabilities) => ({
  postBankDetails: ({ payload }: PostBankDetailsProps) =>
    percentClient.post<PBPResponse<BankDetail>>('v1/bank-details-review', payload.formData),
  getBankDetails: () =>
    percentClient.get<PBPResponse<BankDetail>>('v1/bank-details-review').then(getDataFromPBPResponse)
})
