import { useEffect, useState, useCallback } from 'react'

import { APIErrorHandler } from '../../library/APIErrorHandler'
import { useTranslation } from 'react-i18next'
import { PercentAPIError } from '@percent/cause-dashboard/common/hooks/http.types'

export const useQuery = <A, B>(api: (_: A) => Promise<B>, initialQuery: A) => {
  const [data, setData] = useState<B | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<PercentAPIError | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [queryParams, setQueryParams] = useState(initialQuery)
  const query = useCallback((newQueryParams: any) => {
    setQueryParams(newQueryParams)
  }, [])

  const refresh = useCallback(() => {
    setQueryParams({ ...queryParams })
  }, [queryParams])

  const { t } = useTranslation()

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const result = await api(initialQuery)

        if (!didCancel) {
          if (!result) {
            setErrorMessage(t('errorMessage.noResult'))
          }
          setData(result)
          setIsLoading(false)
        }
      } catch (err: any) {
        if (!didCancel) {
          setErrorMessage(APIErrorHandler(err?.response?.data?.error))
          setError(err?.response?.data?.error)
          setIsLoading(false)
        }
      }

      return () => {
        didCancel = true
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, queryParams])

  return [
    {
      data,
      isLoading,
      errorMessage,
      error
    },
    {
      refresh,
      query
    }
  ] as const
}
