import React from 'react'
import { FormikErrors } from 'formik'

import styles from './FieldError.module.scss'
import { Typography } from '@material-ui/core'

export function FieldError(
  { error }: { error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] } = {
    error: ''
  }
) {
  return (
    <Typography className={styles.fieldError} variant="body2" color="primary">
      <>&nbsp; {error}</>
    </Typography>
  )
}
