import { Dialog } from '@material-ui/core'
import { PartnerApplicationModalContent } from './PartnerApplicationModalContent'
import { ValidationSubmission } from '@percent/cause-dashboard/services/applications/validationSubmission.types'

type ModalContainerProps = {
  isModalOpen: boolean
  onClose: () => void
  validationData: ValidationSubmission | undefined
  children?: React.ReactNode
}

export function PartnerApplicationModalContainer({
  isModalOpen,
  onClose,
  validationData
}: Readonly<ModalContainerProps>) {
  return (
    <Dialog
      open={isModalOpen}
      fullWidth
      maxWidth={false}
      PaperProps={{
        style: {
          width: '960px',
          height: '548px',
          minWidth: '600px',
          minHeight: '450px'
        }
      }}
      data-testid="partner-application-modal"
    >
      <PartnerApplicationModalContent onClose={onClose} validationData={validationData} />
    </Dialog>
  )
}
