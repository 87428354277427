import axios from 'axios'

import { config } from '@percent/cause-dashboard/config'
import { logger } from '@percent/cause-dashboard/common/utility/logger'

export const percentClient = axios.create({
  baseURL: config.urls.api,
  withCredentials: true
})

percentClient.interceptors.response.use(
  responseConfig => responseConfig,
  error => {
    const errorCodesToLog = [400, 403, 404]
    if (error?.response?.status && errorCodesToLog.includes(error?.response?.status)) {
      logger(error)
    }

    throw error
  }
)
