import { Tooltip } from '@material-ui/core'
import React, { ReactElement } from 'react'

import styles from './CustomTooltip.module.scss'

type CustomTooltipProps = {
  placement:
    | 'top-start'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top'
    | undefined
  title: string
  className?: string
  children: ReactElement
}

export function CustomTooltip({ children, title, placement = 'top-start', className = 'tootTip' }: CustomTooltipProps) {
  return (
    <Tooltip title={title} aria-label="add" data-testid="popover" placement={placement} className={styles[className]}>
      {children}
    </Tooltip>
  )
}
