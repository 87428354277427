import { useEffect, useRef } from 'react'
import { environments } from '@percent/cause-dashboard/constants/environments'
import { environment } from '@percent/cause-dashboard/config'

const PLAUSIBLE_SCRIPT_ID = 'plausible-script'

export function usePlausible() {
  const scriptRef = useRef<HTMLScriptElement | null>(null)

  useEffect(() => {
    const isProdEnvironment = environment === environments.PRODUCTION

    if (!isProdEnvironment) {
      scriptRef.current = null
      return
    }

    const plausibleScript = document.getElementById(PLAUSIBLE_SCRIPT_ID)

    if (plausibleScript) {
      scriptRef.current = plausibleScript as HTMLScriptElement
    } else {
      const newPlausibleScript = document.createElement('script')
      newPlausibleScript.setAttribute('id', PLAUSIBLE_SCRIPT_ID)
      newPlausibleScript.setAttribute('src', 'https://plausible.io/js/script.outbound-links.js')
      newPlausibleScript.setAttribute('defer', 'true')
      newPlausibleScript.setAttribute('data-domain', 'https://causes.goodstack.io')

      document.body.appendChild(newPlausibleScript)
      scriptRef.current = newPlausibleScript
    }
  }, [])

  return scriptRef
}
