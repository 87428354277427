import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { Button, ButtonText, FormField, Select, TextInput } from '@percent/lemonade'
import styles from './ClaimSearchHeaderForm.module.scss'
import { ClaimSearchHeaderFormProps } from './ClaimSearchHeaderForm.types'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import useCountries from '@percent/cause-dashboard/common/hooks/useCountries/useCountries'
import { useMyCountryFromIp } from '@percent/cause-dashboard/common/hooks/useMyCountryFromIp/useMyCountryFromIp'
import { useLocalUserPreference } from '@percent/cause-dashboard/common/hooks/useLocalUserPreference/useLocalUserPreference'

export function ClaimSearchHeaderForm({ onSearchClick }: ClaimSearchHeaderFormProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const [disableSearchButton, setDisableSearchButton] = useState(false)
  const countries = useCountries()
  const countryCodes = countries.map(({ name, code }: { name: string; code: string }) => ({
    label: name,
    value: code
  }))

  const { country: myCountry } = useMyCountryFromIp({
    useLocalCache: true
  })

  const [userPreferenceLocalCountry, setUserPreferenceLocalCountry] = useLocalUserPreference('country')

  const { errors, values, setFieldValue, handleSubmit, handleBlur } = useFormik({
    enableReinitialize: false,
    initialValues: {
      countryCode: userPreferenceLocalCountry ?? myCountry ?? '',
      query: ''
    },
    validationSchema: () => {
      if (disableSearchButton) {
        return Yup.object().shape({})
      }

      return Yup.object().shape({
        countryCode: Yup.string().max(6, 'Must be 6 characters or less').required('Required'),
        query: Yup.string().max(500, 'Must be fewer than 500 characters')
      })
    },
    onSubmit: ({ countryCode, query }) => {
      setUserPreferenceLocalCountry(countryCode, { expiresInHours: 24 * 7 })
      onSearchClick(countryCode, query)
    }
  })

  useEffect(() => {
    if (!userPreferenceLocalCountry) {
      return
    }
    setFieldValue('countryCode', userPreferenceLocalCountry ?? myCountry ?? '')
  }, [myCountry, setFieldValue, userPreferenceLocalCountry])

  const searchInputRef = React.createRef<HTMLInputElement>()

  useEffect(() => {
    searchInputRef.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errors.query) {
      setDisableSearchButton(true)
    } else {
      setDisableSearchButton(false)
    }
  }, [errors])

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Grid className={styles.mainGrid} container spacing={3}>
          <Grid item xs={12} md={5}>
            <FormField label={t('typography.selectCountry')}>
              <Select
                options={countryCodes}
                defaultValue={countryCodes.find(country => country.value === values.countryCode)}
                onChange={event => {
                  setFieldValue('countryCode', event.value)
                }}
                data-testid={'select-toggle'}
                searchable
              />
            </FormField>
          </Grid>

          <Grid item xs={7} md={5}>
            <FormField
              label={t('typography.organizationRegisteredNumber')}
              statusMessage={errors.query}
              data-testid="search-header-search-input"
            >
              <TextInput
                name="query"
                value={values.query}
                onChange={a => setFieldValue('query', a.target.value)}
                onBlur={handleBlur}
                ref={searchInputRef}
                placeholder={t('typography.organizationNamePlaceholder')}
              />
            </FormField>
          </Grid>

          <Grid item xs={5} md={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
            <Button stretch disabled={disableSearchButton} data-testid="search-header-search-button" type="submit">
              {t('button.search')}
            </Button>
          </Grid>

          <Grid item className={styles.linkContainer}>
            <ButtonText onPress={() => history.push(RoutePath.ADD_ORGANIZATION)} data-testid="cant-find-org-button">
              {t('typography.cantFindMyOrganization')}
            </ButtonText>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}
