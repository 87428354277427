import { isTablet, isDesktop, isMobileOnly } from 'react-device-detect'

enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  Unknown = 'unknown'
}

export const getDeviceType = () =>
  isMobileOnly ? DeviceType.Mobile : isTablet ? DeviceType.Tablet : isDesktop ? DeviceType.Desktop : DeviceType.Unknown
