import { AxiosInstance } from 'axios'

export enum AgentVerificationStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING_REVIEW = 'pending_review',
  PENDING_USER_VERIFICATION = 'pending_user_verification'
}

export type AgentVerificationConfirmation = {
  id: string
  firstName: string
  lastName: string
  organisationId: string
  email: string
  language: string
  validationRequestId: string
  title: string
  createdAt: string
  status: AgentVerificationStatus
  logo: string
  agentVerificationRedirectUrl: string
  partnerId: string
  configuration: { name: string }
}

export type AgentVerificationServiceCapabilities = {
  percentClient: AxiosInstance
}

export type AgentVerificationAssociation = {
  associationAlreadyRecorded: boolean
  firstName: string
  lastName: string
  partnerName: string
  language: string
}

export type PostAgentVerificationDocumentsParams = {
  agentVerificationId: string
  agentVerificationToken: string
  formData: FormData
}

export type AgentVerificationDocumentStatusResponse = {
  id: string
  status: AgentVerificationStatus
  hasUploadedDocuments: boolean
  partnerName: string
  organisationName: string
}
