import React from 'react'

import { Badge } from '@percent/lemonade'
import { ClaimStatusProps } from './ClaimStatus.types'
import { claimStatusMap } from './claimStatusMap'
import { useTranslation } from 'react-i18next'

export function ClaimStatus({ claimStatus }: ClaimStatusProps) {
  const { t } = useTranslation()
  return (
    <Badge variant={claimStatusMap[claimStatus].variant} icon={claimStatusMap[claimStatus].icon}>
      {t(claimStatusMap[claimStatus].text)}
    </Badge>
  )
}
