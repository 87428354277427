import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { LocationState } from '../TwoFactorAuthentication.types'

import { useAuthState } from '@percent/cause-dashboard/common/hooks/useAuthState/useAuthState'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { LoginWithRecoveryCode } from './LoginWithRecoveryCode'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'

export function LoginWithRecoveryCodeContainer() {
  const {
    authState: { user }
  } = useAuthState()
  const { authService } = useServices()
  const { push } = useHistory()
  const [isRecoveryCodeConfirmed, setIsRecoveryCodeConfirmed] = useState(false)
  const { state } = useLocation<LocationState>()
  const [{ errorMessage, isLoading, data: response, success }, { apiFunc: loginFunc, setErrorMessage }] = useMutation(
    authService.loginWithRecoveryCode,
    () => {
      localStorage.authState = JSON.stringify({
        isAuthorised: true,
        isAuthorising: false
      })
    }
  )

  useEffect(() => {
    if (!state?.password) {
      push('/signin')
    }
  }, [state, push])

  useEffect(() => {
    if (success || user?.twoFaBackupCode) {
      setIsRecoveryCodeConfirmed(true)
    }
  }, [success, user])

  return (
    <LoginWithRecoveryCode
      userData={response?.data?.data}
      errorMessage={errorMessage}
      isLoading={isLoading}
      loginFunc={loginFunc}
      isRecoveryCodeConfirmed={isRecoveryCodeConfirmed}
      setErrorMessage={setErrorMessage}
    />
  )
}
