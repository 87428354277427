import React from 'react'

import { Button, Spacer, SuccessIcon, Text } from '@percent/lemonade'
import styles from './SetupComplete.module.scss'
import { useTranslation } from 'react-i18next'
import { SetupCompleteProps, SetupVersion } from './SetupComplete.types'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components/centralCardLayoutWrapper/centralCardLayoutWrapper'

export function SetupComplete({ onFinish, setupVersion = SetupVersion.Enable2fa }: SetupCompleteProps) {
  const { t } = useTranslation()

  return (
    <CentralCardLayoutWrapper
      heading={setupVersion === SetupVersion.Enable2fa ? t('2fa.completeHeader') : t('2fa.disableCompleted')}
      CustomHeaderImage={
        <>
          {SuccessIcon}
          <Spacer size={6} axis="vertical" />
        </>
      }
    >
      <div className={styles.container}>
        <Text align="left">{t('2fa.completeSubtext')}</Text>
        <Spacer size={6} axis="vertical" />
        <Button type="button" onPress={onFinish} stretch data-testid="complete-btn">
          {t('2fa.complete')}
        </Button>
      </div>
    </CentralCardLayoutWrapper>
  )
}
