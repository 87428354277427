import { AxiosInstance } from 'axios'

import { User } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'
import { apiPaths } from '@percent/cause-dashboard/services/apiPaths'
import { PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { LoginType, OptKeyConfirmResponse, OtpKeyResponse } from './auth.types'
import { getLoginHeadersForApp } from '@percent/domain/iam'

type AuthServiceCapabilities = {
  percentClient: AxiosInstance
}

export const authService = ({ percentClient }: AuthServiceCapabilities) => ({
  login: (loginDetails: { email: string; password: string; enforce?: boolean }) =>
    percentClient.post(
      `${apiPaths.v1Auth}login`,
      { ...loginDetails },
      {
        headers: getLoginHeadersForApp('causeDashboard')
      }
    ),
  forgotPassword: (email: string) =>
    percentClient.post(`${apiPaths.v1Auth}forgot-password`, { accountType: 'cause', email: email.trim() }),
  logout: () => percentClient.post(`${apiPaths.v1Auth}logout`),
  setPassword: ({ password, hash }: { hash: string; password: string }) =>
    percentClient.post(`${apiPaths.v1Auth}reset-password`, { password, hash }),
  verifyAccount: (hash: string) => percentClient.post(`${apiPaths.v1Auth}verify`, { hash }),
  verifyAccountRequest: () => percentClient.post(`${apiPaths.v1Auth}verify-request`, { accountType: 'cause' }),
  signUp: ({ email, password, fullName, validationSubmissionSourceId, phoneNumber }: LoginType) =>
    percentClient.post(`${apiPaths.v1Auth}signup`, {
      email: email.trim(),
      password,
      accountType: 'cause',
      fullName,
      validationSubmissionSourceId,
      phoneNumber
    }),
  confirmOtpKey: ({ otpCode }: { otpCode: string }) =>
    percentClient.post<PBPResponse<OptKeyConfirmResponse>>('v1/account/otp-key-confirm', { otpCode }),
  generateQRCode: ({ password }: { password: string }) =>
    percentClient.post<OtpKeyResponse>('/v1/account/otp-key', { password }),
  verifyOtpKey: ({ token }: { token: string }) => percentClient.post('v1/auth/otp-verify', { token }),
  loginWithRecoveryCode: ({ backupCode }: { backupCode: string }) =>
    percentClient.post<PBPResponse<User>>('/v1/auth/recovery', { backupCode }),
  disable2fa: ({ password }: { password: string }) =>
    percentClient.post<OtpKeyResponse>('/v1/account/otp-key-remove', { password })
})
