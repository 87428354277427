export const initialSelectedCharityState = {
  selectedCharityName: null,
  selectedCharityCountryCode: null,
  selectedCharityId: null
}

export const SET_SELECTED_CHARITY = 'auth/set-selected-charity'

export type selectedCharityType = {
  selectedCharityName: string | null
  selectedCharityCountryCode: string | null
  selectedCharityId: string | null
}

export type SelectedCharityAction = {
  type: string
  payload: {
    selectedCharity?: selectedCharityType
  }
}

export const selectedCharityReducer = (state: selectedCharityType, action: SelectedCharityAction) => {
  switch (action.type) {
    case SET_SELECTED_CHARITY:
      return {
        ...state,
        ...action.payload.selectedCharity
      }
    default:
      return state
  }
}
