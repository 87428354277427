import { useWizard } from '../Wizard/useWizard/useWizard'

import { Button, Spacer, Text, FormField, TextInput } from '@percent/lemonade'
import styles from './SaveBackupCodeStep.module.scss'
import { useTranslation } from 'react-i18next'
import { SaveBackupCodeStepProps } from './SaveBackupCodeStep.types'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components/centralCardLayoutWrapper/centralCardLayoutWrapper'

export function SaveBackupCodeStep({ otpCode = '' }: SaveBackupCodeStepProps) {
  const { t } = useTranslation()
  const { setIsAuthenticationComplete } = useWizard()

  const handleClick = () => setIsAuthenticationComplete(true)

  return (
    <CentralCardLayoutWrapper heading={t('2fa.saveBackupCode')}>
      <div className={styles.container}>
        <Text align="left">{t('2fa.saveBackupCodeSubtext')}</Text>
        <Spacer size={6} axis="vertical" />
        <FormField label={t('2fa.backupCode')} disabled={true} data-testid="opt-code">
          <TextInput value={otpCode} name="secret" disabled={true} />
        </FormField>
        <Spacer size={6} axis="vertical" />
        <Button onPress={handleClick} type="button" stretch>
          {t('2fa.completeSetup')}
        </Button>
      </div>
    </CentralCardLayoutWrapper>
  )
}
