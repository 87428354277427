import { baseUrl } from '@percent/cause-dashboard/services/apiPaths'
import { PBPListResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { config } from '@percent/cause-dashboard/config'
import { RegistriesType, payoutsServiceCapabilities } from './registries.types'

export const registriesService = ({ percentClient }: payoutsServiceCapabilities) => ({
  getRegistries: (countryCode: string) =>
    percentClient.get<PBPListResponse<RegistriesType>>(`${baseUrl}registries?countryCode=${countryCode}`, {
      headers: {
        Authorization: config.keys.pk
      },
      withCredentials: false
    })
})
