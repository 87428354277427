import { ProgramName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'

export enum ProductOfferType {
  PercentService = 'PercentService',
  PartnerProduct = 'PartnerProduct',
  PercentProduct = 'PercentProduct'
}

const LinkTypes = ['calendly', 'typeform', 'external'] as const

export type LinkTypeName = typeof LinkTypes[number]

interface BaseLink {
  linkType: LinkTypeName
  link: string
  linkText: LocaleKey
}

export interface CalendlyLink extends BaseLink {
  linkType: 'calendly'
}

export interface TypeformLink extends BaseLink {
  linkType: 'typeform'
  typeformId: string
}

export interface ExternalLink extends BaseLink {
  linkType: 'external'
}

export type PrimaryButtonLink = TypeformLink | CalendlyLink | ExternalLink
export type SecondaryButtonLink = PrimaryButtonLink | undefined

export type ProductOffer = {
  type: ProductOfferType
  name: string
  tracking: ProgramName
  description: string | React.ReactNode
  subdescription?: string
  subsubdescription?: string
  logo?: string
  globalOverride?: boolean
  primaryButton: PrimaryButtonLink
  secondaryButton: SecondaryButtonLink
}
