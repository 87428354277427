import { Typography, Grid, Box } from '@material-ui/core'
import React from 'react'

import styles from './TitleCard.module.scss'
import { TitleCardProps } from './TitleCard.types'

export function TitleCard({ title, description, children, size = 6 }: TitleCardProps) {
  return (
    <Grid item xs={12} sm={size}>
      <div className={styles.titleCardContainer}>
        <Grid container>
          <Grid container item xs={12} sm={12}>
            <Box>
              <Typography variant="h5" className={styles.title} style={{ display: 'inline-block' }}>
                {title}
              </Typography>
              {description && <Typography className={styles.description}>{description}</Typography>}
            </Box>
          </Grid>
        </Grid>
        {children}
      </div>
    </Grid>
  )
}
