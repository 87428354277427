import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'

import { ReactComponent as PlusIcon } from '@percent/cause-dashboard/common/assets/images/plus-icon.svg'
import bgGoodstackBulletPoints from '@percent/cause-dashboard/common/assets/images/bg-goodstack-bullet-points.png'
import styles from './TitleAndBullets.module.scss'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'

type TitleAndBulletsProps = {
  title: string
  bullets: { title: LocaleKey }[]
}

export function TitleAndBullets({ title, bullets }: TitleAndBulletsProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.bgImageWrapper} style={{ backgroundImage: `url(${bgGoodstackBulletPoints})` }}>
      <Grid className={styles.mainGrid}>
        <Container maxWidth="sm" className={styles.titleGrid}>
          <Typography className={styles.titleStyle}>{title}</Typography>
        </Container>
        <Container maxWidth="sm" className={styles.subTitleGrid}>
          <Typography className={styles.subTitleStyle}>Our platform allows you to:</Typography>
        </Container>
        {bullets.map(({ title: bulletTitle }) => (
          <Container maxWidth="sm" key={bulletTitle}>
            <List>
              <ListItem className={styles.listItem}>
                <ListItemIcon className={styles.listItemIcon}>
                  <PlusIcon />
                </ListItemIcon>
                <ListItemText className={styles.bulletTextStyle} primary={t(bulletTitle)} />
              </ListItem>
            </List>
          </Container>
        ))}
      </Grid>
    </div>
  )
}
