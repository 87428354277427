import { ReactElement, useMemo } from 'react'

import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { SET_SESSION_EXPIRED } from '@percent/cause-dashboard/context/auth'
import { percentClient } from '@percent/cause-dashboard/capabilities/percentClient'
import { useAnalytics } from '@percent/cause-dashboard/common/hooks/useAnalytics/useAnalytics'

export const WithAxios = ({ children }: { children: ReactElement }) => {
  const { authDispatch } = useAuthDispatch()
  const analytics = useAnalytics()

  useMemo(() => {
    percentClient.interceptors.response.use(
      responseConfig => responseConfig,
      error => {
        if (error?.response?.status === 401) {
          authDispatch({
            type: SET_SESSION_EXPIRED
          })

          analytics?.reset()
          return
        }
        throw error
      }
    )
  }, [authDispatch, analytics])

  return children
}
