import { Container, Toolbar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './NavigationBar.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { SET_LOGOUT } from '@percent/cause-dashboard/context/auth'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { useAnalytics } from '@percent/cause-dashboard/common/hooks/useAnalytics/useAnalytics'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'
import { Logo } from '@percent/lemonade'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import { GOODSTACK_WEBSITE } from '@percent/cause-dashboard/constants/general'

export function NavigationBar() {
  const {
    authState: { isAuthorised }
  } = useAuthState()
  const { t } = useTranslation()
  const { authService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const analytics = useAnalytics()

  const handleLogout = () => {
    authService.logout().then(() => {
      analytics?.reset()
      authDispatch({
        type: SET_LOGOUT
      })
    })
  }

  return (
    <Container className={styles.appBar}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <a href={GOODSTACK_WEBSITE}>
            <Logo />
          </a>
          <div className={styles.logInOutButtonDiv}>
            <a href={HELP_LINK} className={styles.navLink}>
              {t('button.getHelp')}
            </a>
            <span className={styles.navDivider} />
            {isAuthorised ? (
              <Link to="/" onClick={handleLogout} className={styles.navLink}>
                {t('button.logout')}
              </Link>
            ) : (
              <Link to={RoutePath.SIGNIN} className={styles.navLink}>
                {t('button.logIn')}
              </Link>
            )}
          </div>
        </Toolbar>
      </Container>
    </Container>
  )
}
