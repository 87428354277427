import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import { matchPath } from 'react-router-dom'
import { differenceInMinutes } from 'date-fns'
import { config } from '@percent/cause-dashboard/config'
import { PromotionalPopupInfo } from './PromotionalPopupContextController.types'

const popupInfoKey = 'promotionalPopup'

export const enabledRoutes = [
  RoutePath.PROFILE,
  RoutePath.DONATIONS,
  RoutePath.DONATION_FORM,
  RoutePath.MILESTONES,
  RoutePath.MY_ACCOUNT
]

export const getPopupData = () => {
  const popupInfoString = localStorage.getItem(popupInfoKey)
  return popupInfoString ? (JSON.parse(popupInfoString) as PromotionalPopupInfo) : undefined
}

export const isRouteEnabled = (pathname: string) => {
  return enabledRoutes.some(path => matchPath(pathname, { path }))
}

export const isInAllowedTimeRange = (popupInfo?: PromotionalPopupInfo) => {
  if (!popupInfo) return true

  const { openingDate, displaysCount } = popupInfo
  const timeElapsed = differenceInMinutes(new Date(), new Date(openingDate))
  const expectedBreakTime =
    displaysCount % 3 === 0 ? config.promotionalPopup.longBreakTime : config.promotionalPopup.shortBreakTme

  return expectedBreakTime <= timeElapsed
}

export const updatePopupData = (popupInfo?: PromotionalPopupInfo) => {
  const data: PromotionalPopupInfo = {
    openingDate: new Date().toISOString(),
    displaysCount: (popupInfo?.displaysCount ?? 0) + 1
  }
  localStorage.setItem(popupInfoKey, JSON.stringify(data))
}
