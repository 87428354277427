import { createContext } from 'react'

import { NotificationBarContextProps } from './NotificationBarContext.types'

const defaultNotificationBarContext = {
  isOpened: false,
  setIsOpened: () => undefined
}

export const NotificationBarContext = createContext<NotificationBarContextProps>(defaultNotificationBarContext)
