import { Card, Text } from '@percent/lemonade'
import styles from './OrganisationCard.module.scss'
import { OrganisationCardProps } from './OrganisationCard.types'
import { Box } from '@mui/material'

export function OrganisationCard({ website, name, logo }: Readonly<OrganisationCardProps>) {
  return (
    <Card>
      <Box className={styles.container}>
        <Box className={styles.textWrapper}>
          <Text size="large" fontWeight="bold" dataTestId="title">
            {name}
          </Text>
          {website && (
            <div className={styles.websiteCtn}>
              <span className={styles.websiteWrapper}>
                <Text color="#8a4aff" dataTestId="website">
                  {<a href={website}> {website} </a>}
                </Text>
              </span>
            </div>
          )}
        </Box>
        {logo && (
          <Box>
            <img src={logo} alt="organisation logo" className={styles.image} data-testid="logo" />
          </Box>
        )}
      </Box>
    </Card>
  )
}
