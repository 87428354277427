import React from 'react'
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Typography } from '@material-ui/core'

import styles from './MenuListComposition.module.scss'
import { t } from 'i18next'

type MenuListCompositionProps = {
  menuItemName: Array<{ title: string; openModal: () => void }>
}

export function MenuListComposition({ menuItemName }: MenuListCompositionProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef?.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div>
      <Typography
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={styles.dropdownTab}
        data-testid="open-menu-list-composition"
      >
        {t('typography.more')}
      </Typography>
      <Popper
        className={styles.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={styles.menuListContainer}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {menuItemName.map(({ openModal, title }: { openModal: () => void; title: string }) => {
                    return (
                      <MenuItem data-testid={`menu-item-${title}`} key={`menu-item-${title}`} onClick={openModal}>
                        {title}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
