import { Button, Heading, Spacer, Text } from '@percent/lemonade'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './OrganizationNotFound.module.scss'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'

export function OrganizationNotFound() {
  const { t } = useTranslation()
  const { push } = useHistory()

  function addOrganizationLink() {
    push(RoutePath.ADD_ORGANIZATION)
  }

  return (
    <div className={styles.organizationNotFound}>
      <Heading level="h5">{t('typography.noCauseFoundText.title')}</Heading>
      <Spacer size={4} axis="vertical" />
      <Text size="small">{t('typography.noCauseFoundText.detail')}</Text>
      <Spacer size={4} axis="vertical" />
      <Button onPress={addOrganizationLink}>{t('button.addNonprofit')}</Button>
    </div>
  )
}
