import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { TableHeadSorterProps } from './TableHeadSorter.types'
import { Icon, Tooltip } from '@percent/lemonade'
import styles from '../Table.module.scss'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'

export function TableHeadSorter({ onRequestSort, order, orderBy, cells }: TableHeadSorterProps) {
  const { t } = useTranslation()
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (onRequestSort) {
      onRequestSort(event, property)
    }
  }

  return (
    <TableHead>
      <TableRow>
        {cells.map(cell => {
          const headerCell = cell.id.substring(cell.id.indexOf('.') + 1)
          return cell.isSortable ? (
            <TableCell
              className={styles.headerCell}
              {...cell.props}
              style={cell.style}
              key={headerCell}
              sortDirection={orderBy === headerCell ? order : false}
            >
              <TableSortLabel
                active={orderBy === headerCell}
                direction={orderBy === headerCell ? order : 'asc'}
                onClick={createSortHandler(headerCell)}
              >
                {cell.description ? (
                  <Tooltip content={cell.description}>
                    {t(cell.id as LocaleKey)}
                    <Icon name="info" size={6} color="gray400" />
                  </Tooltip>
                ) : (
                  <>{t(cell.id as LocaleKey)}</>
                )}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell className={styles.headerCell} {...cell.props} style={cell.style} key={headerCell}>
              {cell.description ? (
                <Tooltip content={cell.description}>
                  {t(cell.id as LocaleKey)}
                  <Icon name="info" size={6} color="gray400" />
                </Tooltip>
              ) : (
                <>{t(cell.id as LocaleKey)}</>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
