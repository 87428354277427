import { Typography } from '@material-ui/core'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { SET_AUTHORISED } from '@percent/cause-dashboard/context/auth'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { LoginType } from '@percent/cause-dashboard/services/auth/auth.types'
import { Alert, Button, ButtonText, FormField, Spacer, TextInput } from '@percent/lemonade'
import { emailRegex } from '@percent/utility'
import { FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { RoutePath } from '../../routes/Routes'
import styles from './LoginPage.module.scss'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components/centralCardLayoutWrapper/centralCardLayoutWrapper'

const REQUIRED_2FA_ERROR = 'auth/requires_two_fa_setup'
const REQUIRED_2FA_LOGIN = 'auth/requires_two_fa_login'

export function LoginPage() {
  const { authService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const {
    authState: { isAuthorised }
  } = useAuthState()
  const { t } = useTranslation()
  const { push } = useHistory()
  const { track, identify } = useCausesPortalAnalytics()
  const history = useHistory()

  const { search } = useLocation()
  const nextPath = new URLSearchParams(search).get('next')
  const redirectAfterLogin = nextPath && nextPath !== RoutePath.SIGNIN && nextPath.length > 0

  useEffect(() => {
    // check if that is required, authReducer should handle authorisation, so it can be redundant, but will have to check that after
    // releasing the API
    if (isAuthorised) {
      push('/nonprofits')
    }
  }, [isAuthorised, push])

  const loginAndUpdateUser = (loginDetails: LoginType) =>
    authService
      .login({
        ...loginDetails,
        enforce: true
      })
      .then(async resp => {
        const { data } = resp.data
        const { id } = data

        await identify({ id, traits: { email: loginDetails?.email } })
        await track('Logged In')

        return authDispatch({
          type: SET_AUTHORISED,
          payload: {}
        })
      })
      .catch(error => {
        if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_ERROR) {
          push('/signin/2fa-setup', {
            password: loginDetails.password
          })
        } else if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_LOGIN) {
          push('/signin/2fa', {
            password: loginDetails.password
          })
        } else {
          throw error
        }
      })

  const [{ errorMessage, isLoading }, { apiFunc: loginUser }] = useMutation(loginAndUpdateUser)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: () =>
      yup.object().shape({
        email: yup.string().required('Required').trim().matches(emailRegex, t('errorMessage.validEmail')),
        password: yup.string().required('Required')
      }),
    onSubmit: (loginDetails: LoginType) => {
      loginUser(loginDetails)
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <CentralCardLayoutWrapper
      heading={t('form.signIn')}
      handleSubmit={handleSubmit}
      backgroundImages
      error={errorMessage}
    >
      {redirectAfterLogin && (
        <>
          <Alert variant="info" title={t('form.signInToContinue')} />
          <Spacer size={4} axis="vertical" />
        </>
      )}
      <FormikProvider value={formik}>
        <FormField
          label={t('form.email')}
          status={touched.email && errors.email ? 'danger' : 'default'}
          statusMessage={errors.email}
          data-testid="email"
          necessity="required"
        >
          <TextInput
            name="email"
            placeholder={t('form.enterEmailAddress')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </FormField>
        <Spacer size={4} axis="vertical" />
        <FormField
          label="Password"
          status={touched.password && errors.password ? 'danger' : 'default'}
          statusMessage={errors.password}
          data-testid="password"
          necessity="required"
        >
          <TextInput
            type="password"
            name="password"
            placeholder="Enter password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
        </FormField>
        <Spacer size={4} axis="vertical" />
        <div className={styles.forgotPasswordText}>
          <ButtonText onPress={() => history.push(RoutePath.FORGOT_PASSWORD)}>{t('form.forgotPassword')}</ButtonText>
        </div>
        <Button size="large" stretch data-testid="auth-active-button" type="submit" loading={isLoading}>
          {t('form.signIn')}
        </Button>
        <div className={styles.loginFormFooter}>
          <Typography variant="body2" className={styles.noAccountYet}>
            {t('form.noAccount')}
          </Typography>
          <ButtonText onPress={() => history.push(RoutePath.CLAIM)}>{t('form.nonProfitAndSignUp')}</ButtonText>
        </div>
      </FormikProvider>
    </CentralCardLayoutWrapper>
  )
}
