import QRCode from 'qrcode.react'

import { useWizard } from '../Wizard/useWizard/useWizard'

import { Button, Spacer, Text, FormField, TextInput, ButtonText } from '@percent/lemonade'
import styles from './SetupQRStep.module.scss'
import { useTranslation } from 'react-i18next'
import { SetupQRStepProps } from './SetupQRStep.types'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components/centralCardLayoutWrapper/centralCardLayoutWrapper'

export function SetupQRStep({ token = '', isLoading, tokenUri }: Readonly<SetupQRStepProps>) {
  const { t } = useTranslation()
  const { setNextStep } = useWizard()
  const { push } = useHistory()

  return (
    <CentralCardLayoutWrapper heading={t('2fa.setupAuthentication')}>
      <div className={styles.container}>
        <Text align="left">{t('2fa.scanApp')}</Text>
        <Spacer size={6} axis="vertical" />
        <QRCode renderAs="svg" className={styles.qrCode} value={tokenUri ?? ''} />
        <Spacer size={4} axis="vertical" />
        <FormField label={t('2fa.secretKey')} disabled={true} data-testid="token">
          <TextInput value={token} name="token" disabled={true} />
        </FormField>
        <Spacer size={2} axis="vertical" />
        <Text align="left" size="small">
          {t('2fa.cantScanQR')}
        </Text>
        <Spacer size={6} axis="vertical" />
        <Button onPressEnd={setNextStep} type="button" stretch loading={isLoading}>
          {t('button.next')}
        </Button>
        <Spacer size={6} axis="vertical" />
        <div className={styles.cancelButtonWrapper}>
          <ButtonText onPressEnd={() => push(RoutePath.MY_ACCOUNT)}>{t('button.cancelSetup')}</ButtonText>
        </div>
      </div>
    </CentralCardLayoutWrapper>
  )
}
