import { useEffect, useState } from 'react'
import { PopupModal as CalendlyWidget } from 'react-calendly'
import { useAuthState } from '../../hooks'
import { useMarketplaceAnalytics } from '../../hooks/useCausesDashboardAnalytics/useMarketplaceAnalytics'
import {
  MarketplaceProgrammeVisitedSource,
  PageViewSource,
  ProgrammeViewEventKind
} from '../../hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

interface CalendlyContainerProps {
  url: string
  selectItem: (offerName: string | undefined) => void
  tracking?: {
    source: string
    programmeName?: string
  }
}

export function CalendlyContainer({ url, selectItem, tracking }: Readonly<CalendlyContainerProps>) {
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)
  const { authState } = useAuthState()
  const { trackProgramViewEvent } = useMarketplaceAnalytics()
  const [hasTrackedLoad, setHasTrackedLoad] = useState(false)

  useEffect(() => {
    const element = document.getElementById('root')
    setRootElement(element)
  }, [])

  useEffect(() => {
    if (!tracking) {
      return
    }

    if (hasTrackedLoad) {
      return
    }
    const trackViewFromCardOnFirstLoadOnly = async () =>
      await trackProgramViewEvent({
        eventKind: ProgrammeViewEventKind.ModalShown,
        programmeName: tracking.programmeName,
        page: PageViewSource.AppStore
      })

    const trackViewFromBookDemoOnFirstLoadOnly = async () =>
      await trackProgramViewEvent({
        eventKind: ProgrammeViewEventKind.ModalShown,
        page: PageViewSource.Profile
      })

    if (tracking.source === MarketplaceProgrammeVisitedSource.Card) {
      trackViewFromCardOnFirstLoadOnly().then(r => setHasTrackedLoad(true))
    } else {
      trackViewFromBookDemoOnFirstLoadOnly().then(r => setHasTrackedLoad(true))
    }
  }, [hasTrackedLoad, tracking, trackProgramViewEvent])

  return (
    <>
      <span data-testid="calendly-widget" id="test-root" />
      {rootElement && (
        <CalendlyWidget
          rootElement={rootElement}
          url={url}
          prefill={{
            email: authState.user?.email,
            name: authState.user?.fullName
          }}
          onModalClose={() => selectItem(undefined)}
          open={true}
        />
      )}
    </>
  )
}

export default CalendlyContainer
