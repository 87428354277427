import { Grid } from '@material-ui/core'
import { DonationsIncludedList } from './DonationsIncludedList/DonationsIncludedList'
import { useLocation } from 'react-router-dom'
import { PayoutDetails } from './PayoutDetails/PayoutDetails'
import { ErrorView } from '@percent/cause-dashboard/common/components/ErrorView'
import { useAuthState, useQueryList } from '@percent/cause-dashboard/common/hooks'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { Loader } from '@percent/cause-dashboard/common/components'
import { toCurrencyMap } from '@percent/cause-dashboard/common/utility/money/currencyMap'
import { dayJS } from '@percent/cause-dashboard/common/utility/date'

export function PayoutDetailsPage() {
  const { pathname } = useLocation()
  const payoutId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)

  const { payoutsService } = useServices()
  const {
    currencyInfo,
    authState: { user }
  } = useAuthState()

  const currencyMap = toCurrencyMap(currencyInfo)

  const [payoutDetailsResponse] = useQuery(payoutsService.getPayoutDetails, {
    organisationId: user?.organisationId as string,
    disbursementId: payoutId
  })

  const [{ data, totalResults, isLoading, errorMessage }, { nextPage, previousPage }] = useQueryList(
    payoutsService.getPayoutDonationsIncludedList(user?.organisationId as string, payoutId)
  )

  if (payoutDetailsResponse.errorMessage || errorMessage) {
    return <ErrorView errorMessage={payoutDetailsResponse.errorMessage || errorMessage} />
  }

  if (payoutDetailsResponse.isLoading || isLoading) {
    return <Loader />
  }

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      <Grid item>
        <PayoutDetails data={payoutDetailsResponse.data?.data.data} currencyMap={currencyMap} />
      </Grid>
      <Grid item>
        <DonationsIncludedList
          currencyMap={currencyMap}
          payoutId={payoutId}
          payoutDate={dayJS(payoutDetailsResponse?.data?.data.data.paidAt).format('YYYY-MM-DD')}
          data={data}
          totalResults={totalResults}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      </Grid>
    </Grid>
  )
}
