import { Heading, Spacer, Text } from '@percent/lemonade'
import { MultiStepFormHeaderProps } from './MultiStepFormHeader.types'

export function MultiStepFormHeader({ title, subtitle }: MultiStepFormHeaderProps) {
  return (
    <>
      {title && (
        <>
          <Spacer size={8} axis="vertical" />
          <Heading level="h4" align="left">
            {title}
          </Heading>
        </>
      )}
      {subtitle && (
        <>
          <Spacer size={6} axis="vertical" />
          <Text align="left">{subtitle}</Text>
        </>
      )}
    </>
  )
}
