import { ReactNode } from 'react'

import { I18nextProvider } from 'react-i18next'
import i18n from '@percent/cause-dashboard/i18n/config'

type AppProvidersProps = {
  children: ReactNode
}

export function AppProviders({ children }: AppProvidersProps) {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
