import { Typography } from '@material-ui/core'
import styles from './ExperimentFastTrackGoogleApplication.module.scss'
import { Button, Spacer } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { useExperiment } from '@percent/cause-dashboard/common/hooks/useExperiment/useExperiment'
import { teamIcedLatteExperiments } from '@percent/cause-dashboard/common/hooks/useExperiment/experiments/teamIcedLatteExperiments'
import { useHistory } from 'react-router-dom'
import { SignUpReason } from '@percent/cause-dashboard/common/hooks/useSignUpAttribution/useSignUpAttribution'
import { getSignUpUrl } from '@percent/cause-dashboard/common/utility/utility'

interface ExperimentFastTrackGoogleApplicationProps {
  partnerId: string
  userLanguage: string
  organizationId?: string
  email: string
}

export const ExperimentFastTrackGoogleApplication = ({
  partnerId,
  userLanguage,
  organizationId,
  email
}: ExperimentFastTrackGoogleApplicationProps) => {
  const { t } = useTranslation()
  const fastTrackGoogleApplicationExperiment = useExperiment(
    teamIcedLatteExperiments.fastTrackGoogleApplication,
    partnerId
  )
  const { push } = useHistory()

  const handleExperimentCtaClick = () => {
    fastTrackGoogleApplicationExperiment.trackEventOnceThisSession('clickCta')
    const url = getSignUpUrl({
      organizationId,
      email,
      signUpReason: SignUpReason.VERIFICATION_SUPPORT,
      signUpPartnerId: partnerId
    })
    push(url)
  }

  if (
    !fastTrackGoogleApplicationExperiment.canPerformExperiment ||
    !organizationId ||
    !userLanguage.toLowerCase().includes('en')
  ) {
    return null
  }

  return (
    <div className={styles.ctaContainer} data-testid="google-fast-track-experiment">
      <Typography variant="subtitle2">{t('typography.googleApplication.title')}</Typography>
      <Spacer size={2} axis="vertical" />
      <div className={`${styles.ctaFlex} ${styles.ctaText}`}>{t('typography.googleApplication.body')}</div>
      <Spacer size={4} axis="vertical" />
      <div className={styles.ctaFlex}>
        <Button size="large" data-testid="sign-up-btn" stretch onPress={handleExperimentCtaClick}>
          {t('typography.experiments.ctaButton')}
        </Button>
      </div>
    </div>
  )
}
