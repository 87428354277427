import styles from './RequestToJoinOrganisation.module.scss'
import { Text, ButtonText } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { RequestToJoinOrganisationProps } from './RequestToJoinOrganisation.types'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'

export function RequestToJoinOrganisation({
  organisationId,
  organisationName
}: Readonly<RequestToJoinOrganisationProps>) {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={styles.requestToJoinContainer} data-testid="request-to-join-organisation-view">
      <div className={styles.requestToJoinTextWrapper}>
        <Text size="small">{t('typography.requestToJoin')}</Text>
      </div>
      <ButtonText
        variant="primary"
        onPressEnd={() => history.push(`${RoutePath.REQUEST_TO_JOIN}?id=${organisationId}&name=${organisationName}`)}
        data-testid="request-to-join-button"
      >
        {t('button.requestToJoin')}
      </ButtonText>
    </div>
  )
}
