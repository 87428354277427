import { SignUpReason } from '../hooks/useSignUpAttribution/useSignUpAttribution'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'

interface SignUpUrlParams {
  organizationId?: string
  email?: string
  signUpReason?: SignUpReason
  signUpPartnerId?: string
}
export const formatMoney = (num: number) => (num / 100).toFixed(2)

export const getQueryParamsFromUrl = <
  A = {
    [k: string]: string
  }
>(
  apiURL: string
): A => {
  const url = new URL(apiURL)
  const searchParams = new URLSearchParams(url.search)

  return Object.fromEntries(searchParams) as unknown as A
}

export const replaceDashWithEmptySpace = (str: string) => str.replace('-', ' ')

export const floatToIntegerCurrency = (inputAmount: number) =>
  parseInt(((inputAmount * 100) / 100).toFixed(2).replace('.', ''), 10)

export const getSignUpUrl = (signUpUrlParams: SignUpUrlParams) => {
  const { organizationId, email, signUpReason, signUpPartnerId } = signUpUrlParams
  const params = new URLSearchParams()

  if (organizationId) {
    params.set('organization-id', organizationId)
  }
  if (email) {
    params.set('email', email)
  }
  if (signUpReason) {
    params.set('r', signUpReason)
  }
  if (signUpPartnerId) {
    params.set('p', signUpPartnerId)
  }
  return `${RoutePath.SIGNUP}?${params.toString()}`
}
