import { useCallback, useEffect, useState } from 'react'
import { useLocalUserPreference } from '@percent/cause-dashboard/common/hooks/useLocalUserPreference/useLocalUserPreference'

const FALLBACK_COUNTRY_CODE_ISO3 = 'GBR'
const CACHE_EXPIRATION_HOURS = 24 * 3

interface IMyCountryFromIp {
  forceIp?: string
  useLocalCache?: boolean
}

export const useMyCountryFromIp = (props?: IMyCountryFromIp) => {
  const { forceIp, useLocalCache } = props || {}
  const [cacheCountry, setMyCountryInCache] = useLocalUserPreference('country')
  const [country, setCountry] = useState<string | null>(useLocalCache ? cacheCountry : null)

  const fetchCountry = useCallback(async () => {
    const queryUrl = forceIp ? `https://ipapi.co/${forceIp}/json/` : 'https://ipapi.co/json/'

    if (country) {
      return
    }

    try {
      const result = await fetch(queryUrl)
      const json = await result.json()

      setCountry(json.country_code_iso3)
      setMyCountryInCache(json.country_code_iso3, {
        expiresInHours: CACHE_EXPIRATION_HOURS
      })

      return json.country_code_iso3
    } catch {
      setCountry(FALLBACK_COUNTRY_CODE_ISO3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    void fetchCountry()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    country: country
  }
}
