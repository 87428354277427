import { Modal, FormField, Select, Button, AcknowledgeModal } from '@percent/lemonade'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'
import { ModalHeader } from '@percent/cause-dashboard/common/components/modal/Modal/ModalHeader'
import { ModalContent } from 'libs/shared/ui-lemonade/src/components/modal-content'
import { ModalFooter } from 'libs/shared/ui-lemonade/src/components/modal-footer/modal-footer'
import styles from './EditUserRoleModal.module.scss'
import { useEffect, useState } from 'react'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useTranslation } from 'react-i18next'
import { IamGetRoles } from '@percent/cause-dashboard/services/types/iamResponses.types'

interface EditUserRoleModalProps {
  accountId: string
  role: {
    id: string
    displayName: string
  }
  email: string
  open: boolean
  close: (refetch: boolean) => void
  roles: IamGetRoles['roles']
}

enum EditRoleModalState {
  FORM,
  STATUS
}

export const EditUserRoleModal = ({ accountId, email, role, open, close, roles: allRoles }: EditUserRoleModalProps) => {
  const { t } = useTranslation()
  const [isRoleSelected, setIsRoleSelected] = useState(false)
  const [selectedRole, setSelectedRole] = useState('')
  const [modalState, setModalState] = useState(EditRoleModalState.FORM)
  const [acknowledgeModalData, setAcknowledgeModalData] = useState({ title: '', description: '', result: '' })
  const { iamService } = useServices()

  const roles =
    allRoles
      ?.map(role => {
        return { label: role.displayName, value: role.id, description: role.description }
      })
      .filter(proposedRole => proposedRole.value !== role.id) || []

  const [{ isLoading: isMutating }, { apiFunc: updateUserRoleAPIRequest }] = useMutation(
    iamService.patchRoles,
    _success => {
      setAcknowledgeModalData({
        title: t('typography.userManagement.editSuccessModal.title'),
        description: t('typography.userManagement.editSuccessModal.description', { email: email, role: selectedRole }),
        result: 'positive'
      })
      setModalState(EditRoleModalState.STATUS)
    },
    _error => {
      setAcknowledgeModalData({
        title: t('typography.userManagement.editFailureModal.title'),
        description: t('typography.userManagement.editFailureModal.description', { email: email }),
        result: 'negative'
      })
      setModalState(EditRoleModalState.STATUS)
    }
  )

  const roleId = role.id

  const formik = useFormik({
    initialValues: {
      roleId: roleId
    },
    validationSchema: () =>
      object().shape({
        roleId: string().required('Required')
      }),
    onSubmit: values => {
      updateUserRoleAPIRequest({
        accountId: accountId,
        roleId: values.roleId
      })
    }
  })

  const { touched, errors, handleSubmit, setFieldValue, resetForm } = formik

  useEffect(() => {
    if (!open) {
      setIsRoleSelected(false)
      resetForm()
      setSelectedRole('')
    }
  }, [resetForm, open])

  return (
    <Modal open={open} onClose={() => close(false)}>
      {modalState === EditRoleModalState.FORM && (
        <form onSubmit={handleSubmit}>
          <FormikProvider value={formik}>
            <ModalHeader onClose={() => close(false)} headerTitle="Edit role" />
            <hr className={styles.divider} />
            <ModalContent>
              <span className={styles.boldText} data-testid={'email'}>
                {email}
              </span>
              {t('typography.userManagement.editUserRoleModal.title', { role: `'${role.displayName}'` })}
              <div className={styles.editUserRoleModalFormFields} data-testid={'edit-role'}>
                <FormField
                  label={t('typography.userManagement.role')}
                  status={touched.roleId && errors.roleId ? 'danger' : 'default'}
                  statusMessage={errors.roleId}
                >
                  <Select
                    name="roleId"
                    data-testid="role-select"
                    placeholder={role.displayName}
                    onChange={selection => {
                      setFieldValue('roleId', selection.value)
                      setIsRoleSelected(true)
                      setSelectedRole(selection.label)
                    }}
                    options={roles}
                  />
                </FormField>
              </div>
            </ModalContent>
            <ModalFooter>
              <Button
                data-testid={'button-cancel'}
                type="button"
                size="large"
                variant="secondary"
                onPress={() => close(false)}
              >
                {t('button.cancel')}
              </Button>
              <Button
                data-testid={'button-save-changes'}
                type="submit"
                size="large"
                variant="primary"
                disabled={!isRoleSelected}
                loading={isMutating}
              >
                {t('button.saveChanges')}
              </Button>
            </ModalFooter>
          </FormikProvider>
        </form>
      )}
      {modalState === EditRoleModalState.STATUS && (
        <AcknowledgeModal
          title={acknowledgeModalData.title}
          handleClose={() => close(acknowledgeModalData.result === 'positive')}
          description={acknowledgeModalData.description}
          result={acknowledgeModalData.result as 'positive' | 'negative'}
          buttonText={t('button.close')}
          buttonTestId="edit-role-acknowledge-modal-button"
          viewTestId="edit-role-acknowledge-modal-view"
        />
      )}
    </Modal>
  )
}
