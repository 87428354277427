import { useState } from 'react'
import { SitewideMessageContextControllerProps } from './SitewideMessageContextController.types'
import { SitewideMessageContext } from '../sitewideMessageContext/SitewideMessageContext'

export const CURRENT_SITEWIDE_MESSAGE_NAME = 'rebrand_notification_message'

export function SitewideMessageContextController({ children }: SitewideMessageContextControllerProps) {
  const [shouldShowBanner, setShouldShowBanner] = useState(!localStorage.getItem(CURRENT_SITEWIDE_MESSAGE_NAME))

  const closeSitewideMessage = () => {
    setShouldShowBanner(false)
    localStorage.setItem(CURRENT_SITEWIDE_MESSAGE_NAME, 'true')
  }

  return (
    <SitewideMessageContext.Provider value={{ closeSitewideMessage, shouldShowSitewideMessage: shouldShowBanner }}>
      {children}
    </SitewideMessageContext.Provider>
  )
}
