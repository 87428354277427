import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLocalUserPreference } from '@percent/cause-dashboard/common/hooks/useLocalUserPreference/useLocalUserPreference'

export enum SignUpReason {
  VERIFICATION_STATUS_CHECK = 'verification_status_check',
  VERIFICATION_SUPPORT = 'verification_support',
  AV_EMAIL_FOOTER = 'av_email_footer',
  AV_DOCUMENT_UPLOAD = 'av_doc_upload',
  RECEIVE_DONATIONS = 'receive_donations',
  INVITED = 'invited'
}

type SignUpAttribution = {
  reason?: string | null
  partnerId?: string | null
}

const CACHE_EXPIRATION_HOURS = 24 * 30

export const useSignUpAttribution = () => {
  // Could expand to object with Attribution, campaign, medium etc in future
  const [cacheAttribution, setMySignUpAttributionInCache] = useLocalUserPreference('signUpAttribution')
  const [signUpAttribution, setSignUpAttribution] = useState<SignUpAttribution | null>(
    JSON.parse(cacheAttribution || '{}')
  )
  const { search } = useLocation()

  const fetchAttribution = useCallback(async () => {
    if (signUpAttribution?.partnerId || signUpAttribution?.reason) {
      return
    }
    const reason = new URLSearchParams(search).get('r')
    const partnerId = new URLSearchParams(search).get('p')
    if ((reason && Object.values(SignUpReason).includes(reason as any)) || partnerId) {
      const signUpAttribution = {
        reason,
        partnerId
      }
      setSignUpAttribution(signUpAttribution)
      setMySignUpAttributionInCache(JSON.stringify(signUpAttribution), {
        expiresInHours: CACHE_EXPIRATION_HOURS
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    void fetchAttribution()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setSignUpAttributionManually = (reason: SignUpReason) => {
    if (signUpAttribution?.partnerId || signUpAttribution?.reason) {
      return
    }

    setSignUpAttribution({ reason })
  }

  return {
    signUpAttribution,
    setSignUpAttributionManually
  }
}
