import { TableCell, TableRow } from '@material-ui/core'
import { Loader } from '@percent/cause-dashboard/common/components'
import { Table } from '@percent/cause-dashboard/common/components/table/Table'
import styles from './InviteesListTable.module.scss'
import { Badge } from '@percent/lemonade'
import { IamGetInvites } from '@percent/cause-dashboard/services/types/iamResponses.types'
import { useTranslation } from 'react-i18next'

interface InviteesListTableProps {
  isLoading: boolean
  data: IamGetInvites[] | null
  totalResultCount: number
  onNextPageClick: (() => void) | null
  onPreviousPageClick: (() => void) | null
}

export function InviteesListTable({
  data,
  isLoading,
  totalResultCount,
  onNextPageClick: nextPage,
  onPreviousPageClick: previousPage
}: Readonly<InviteesListTableProps>) {
  const { t } = useTranslation()

  const columns = [
    { id: 'typography.userManagement.email', isSortable: false, props: { width: '80%' } },
    { id: 'typography.userManagement.role', isSortable: false, props: { width: '20%' } },
    { id: 'typography.userManagement.status', isSortable: false, props: { width: 'auto' } }
  ]

  if (isLoading) {
    return <Loader />
  }

  function getStatus(expiresAtString: string): string {
    const expiresAtDate = new Date(expiresAtString)
    const currentDate = new Date()
    if (expiresAtDate > currentDate) {
      return 'pending'
    } else {
      return 'removed'
    }
  }

  return (
    <Table
      data={data}
      isLoading={isLoading}
      totalResults={totalResultCount || 0}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={columns}
      emptyTableText={t('table.noInvitedUsers')}
      orderBy=""
    >
      <div data-testid={'invitees-table'} />
      {data?.map(({ id, email, role, expiresAt }) => (
        <TableRow key={`permissions-list-${id}`} data-testid={`row-${id}`}>
          <TableCell>
            <div className={styles.cellWrapper}>
              <span>{email}</span>
            </div>
          </TableCell>
          <TableCell data-testid={`row-${id}-role-${role.displayName}`}>
            <Badge variant="default" testId="role-badge">
              {role.displayName}
            </Badge>
          </TableCell>
          <TableCell data-testid={`row-${id}-status`}>
            {getStatus(expiresAt) === 'pending' ? (
              <Badge variant="default" icon="clock">
                {t('typography.userManagement.inviteStatus.pending', { defaultMessage: 'Awaiting response' })}
              </Badge>
            ) : getStatus(expiresAt) === 'removed' ? (
              <Badge variant="default">
                {t('typography.userManagement.inviteStatus.removed', { defaultMessage: 'Expired' })}
              </Badge>
            ) : null}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
