import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'

import styles from './ProductOffersPage.module.scss'

import {
  IProductCategory,
  categorizedProducts as categorizedProductsDefault
} from '@percent/cause-dashboard/app/productOffers/data/categories'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { MarketplaceProgrammeVisitedSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { AuthStateType } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useCalendlyEventListener } from 'react-calendly'
import { AppStoreCard } from './AppStoreCard/AppStoreCard'
import { MarketplaceBanner } from './MarketPlaceBanner/MarketplaceBanner'
import { ProductOffer } from './data/data.types'
import { useSitewideMessage } from '@percent/cause-dashboard/common/hooks/useSitewideMessage/useSitewideMessage'
import { useNotificationBar } from '@percent/cause-dashboard/common/hooks/useNotificationBar/useNotificationBar'

interface ProductOffersPageProps {
  offersOverride?: Array<{ id: number } & IProductCategory>
}

const COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW = ['USA', 'CAN', 'AUS', 'FRA', 'GBR']

const shouldDisplayOffer = (productOffer: ProductOffer, authState: AuthStateType) => {
  const { claimAccepted } = selectAuthState(authState)
  const acceptCallsFromMyCountry = COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW.includes(authState.organisation?.countryCode || '')

  const canBookCall = claimAccepted && acceptCallsFromMyCountry
  // Current logic is that we do not show calendly tiles as these end up with an english-speaking salesperson.
  const isCalendlyCTA =
    productOffer.primaryButton.linkType === 'calendly' || productOffer.secondaryButton?.linkType === 'calendly'
  return productOffer.globalOverride || canBookCall || !isCalendlyCTA
}

export function ProductOffersPage({ offersOverride }: ProductOffersPageProps) {
  const { page } = useCausesPortalAnalytics()
  const { calendlyService } = useServices()
  const { authState } = useAuthState()
  const [{ isLoading }, { apiFunc: saveMeetingLink }] = useMutation(calendlyService.saveMeetingLink)
  const [selectedOfferName, setSelectedOfferName] = useState<string | undefined>(undefined)

  const categorizedProducts = offersOverride ?? categorizedProductsDefault

  useEffect(() => {
    page('Marketplace')
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [doesCountryAcceptCalls, setDoesCountryAcceptCalls] = useState(false)

  const myCountry = authState.organisation?.countryCode

  useEffect(() => {
    if (typeof myCountry === 'string' && COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW.includes(myCountry)) {
      setDoesCountryAcceptCalls(true)
    }
  }, [myCountry])

  // Has to be put in parent not in PartnerCard
  // Listener is global, and was triggering for each card causing multiple http calls
  useCalendlyEventListener({
    onEventScheduled: event => {
      const uri = event.data.payload.event.uri
      if (uri && !isLoading) {
        saveMeetingLink({
          accountId: authState.user?.id,
          organisationId: authState.user?.organisationId ?? '',
          email: authState.user?.email,
          source: selectedOfferName ?? 'app-store-offer',
          meetingLink: uri
        })
      }
      setSelectedOfferName(undefined)
    }
  })

  const notification = useNotificationBar()
  const { shouldShowSitewideMessage } = useSitewideMessage()
  const defaultTop = 70
  const shiftDownToAccomodateNotification = notification.isOpened || shouldShowSitewideMessage ? 48 : 0
  const topPosition = defaultTop + shiftDownToAccomodateNotification

  return (
    <div id="marketplace-container">
      <MarketplaceBanner
        tracking={{
          source: MarketplaceProgrammeVisitedSource.Banner,
          programmeName: 'LinkedIn'
        }}
      />
      <div className={styles.productCategoriesWrapper}>
        {categorizedProducts
          .filter(category =>
            category.offers.some(offer => shouldDisplayOffer(offer, authState) && category.offers.length > 0)
          )
          .map(category => (
            <section
              key={category.id}
              title={category.name}
              className={styles.productOffersWrapper}
              data-testid={category.name}
            >
              <div className={styles.sectionHeader} style={{ top: topPosition }}>
                <div className={styles.sectionTitle}>{category.name}</div>
                <div className={styles.sectionDescription}>{category.description}</div>
              </div>
              <Grid container spacing={3} className={styles.productOffersPageGrid}>
                {category.offers.map((productOffer, offerIndex) => {
                  return (
                    shouldDisplayOffer(productOffer, authState) && (
                      <Grid item sm={12} md={6} lg={4} xl={3} key={productOffer.name}>
                        <AppStoreCard
                          {...productOffer}
                          category={category.name}
                          selectOffer={setSelectedOfferName}
                          selectedOfferName={selectedOfferName}
                          offerIndex={offerIndex + 1}
                          tracking={{
                            name: productOffer.tracking,
                            category: category.tracking
                          }}
                        />
                      </Grid>
                    )
                  )
                })}
              </Grid>
            </section>
          ))}
      </div>
    </div>
  )
}
