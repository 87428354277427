import { AxiosResponseHeaders } from 'axios'
import { baseUrl } from '../apiPaths'
import { ReportingServiceCapabilities, GetDonationReportType } from './reportingService.types'

const getFilename = (headers: AxiosResponseHeaders) => headers['content-disposition']?.split('filename=')?.[1]

export const reportingService = ({ percentClient }: ReportingServiceCapabilities) => ({
  getDonationReport:
    (organisationId: string) =>
    ({ startDate, endDate }: GetDonationReportType): Promise<{ data: string }> =>
      percentClient
        .get(`${baseUrl}reporting/organisations/${organisationId}/donations`, {
          params: {
            startDate,
            endDate
          },
          responseType: 'blob'
        })
        .then(({ data, headers }) => ({
          data,
          fileName: getFilename(headers)
        })),
  getPayoutDonations:
    (organisationId: string, payoutId: string) =>
    (): Promise<{
      data: string
      fileName: string | undefined
    }> =>
      percentClient
        .get(`${baseUrl}organisations/${organisationId}/disbursements/${payoutId}/report`, {
          responseType: 'blob'
        })
        .then(({ data, headers }) => ({
          data,
          fileName: getFilename(headers)
        }))
})
