import { PBPResponse, PBPListResponse } from '../types/pbpResponses.types'

import {
  PostOrganisationLogoProps,
  PatchOrganisationProps,
  CauseServiceCapabilities,
  GetCauseSearchProps,
  OrganisationLogoBody,
  Organisation
} from './causeService.types'
import { config } from '@percent/cause-dashboard/config'
import { apiPaths } from '@percent/cause-dashboard/services/apiPaths'
import { getDataFromPBPResponse } from '@percent/cause-dashboard/common/utility/pbpApi'
import { throttleAsync } from '@percent/utility'

export const causeService = ({ percentClient }: CauseServiceCapabilities) => ({
  getCauseSearch: throttleAsync(
    (params: GetCauseSearchProps) =>
      percentClient.get<PBPListResponse<Organisation>>(apiPaths.v1Cause, {
        params,
        headers: {
          Authorization: config.keys.pk
        },
        withCredentials: false
      }),
    1000,
    { leading: true }
  ),
  getOrganisation: ({ organisationId }: { organisationId: string }) =>
    percentClient
      .get<PBPResponse<Organisation>>(`${apiPaths.v1Cause}${organisationId}`, {
        headers: {
          Authorization: config.keys.pk
        },
        withCredentials: false
      })
      .then(getDataFromPBPResponse),
  patchOrganisation: ({ payload, organisationId }: PatchOrganisationProps) =>
    percentClient.patch<PBPResponse<Organisation>>(`${apiPaths.v1Cause}${organisationId}`, payload),
  postOrganisationLogo: ({ payload, organisationId }: PostOrganisationLogoProps) =>
    percentClient
      .post<PBPResponse<OrganisationLogoBody>>(`${apiPaths.v1Cause}${organisationId}/logo`, payload.formData)
      .then(getDataFromPBPResponse)
})
