import { useContext, useMemo } from 'react'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'

import { Feature, featureNames, featureKeys, features } from '@percent/cause-dashboard/app/providers/SplitSdkProvider'

export function useFeatureFlag() {
  const treatments = useTreatments(featureKeys)
  const { isReady, isTimedout } = useContext(SplitContext)

  const featureFlagValue = useMemo(() => {
    const featureFlags = featureNames.reduce((acc, feature) => {
      acc[feature] =
        (isReady && treatments[features[feature].key].treatment === 'on') ||
        (isTimedout && features[feature].defaultValue === 'on')
      return acc
    }, {} as Record<Feature, boolean>)

    return { ...featureFlags, _meta: { isReady } }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isTimedout, treatments])

  return featureFlagValue
}
