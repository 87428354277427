import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styles from './Footer.module.scss'
import { config } from '@percent/cause-dashboard/config'
import { Spacer } from '@percent/lemonade'

type FooterProps = {
  noTermsAndPrivacyPolicy?: boolean
}

export function Footer({ noTermsAndPrivacyPolicy = false }: FooterProps) {
  const { t } = useTranslation()

  return (
    <footer className={styles.footerContainer}>
      <Typography variant="body1" className={styles.footerText}>
        {t('typography.goodstack')}
        &nbsp;
        <span role="img">©</span>
        &nbsp;
        {new Date().getFullYear()}
        <Spacer size={4} axis="horizontal" />
        <span className={styles.dot}></span>
        <Spacer size={4} axis="horizontal" />
        {!noTermsAndPrivacyPolicy && (
          <span className={styles.legal}>
            <a target="_blank" rel="noreferrer" href={config.urls.terms}>
              {t('typography.termsOfUse')}
            </a>
          </span>
        )}
      </Typography>
    </footer>
  )
}
