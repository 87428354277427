import { milestonesService } from './milestones/milestonesService'
import { countriesService } from './countries/countriesService'
import { Capabilities } from '@percent/cause-dashboard/capabilities/index'
import { claimService } from './claim/claimService'
import { authService } from './auth/auth'
import { causeService } from './cause/cause'
import { accountService } from './account/account'
import { agentVerificationService } from './agentVerification/agentVerification'
import { donationsService } from './donations/donationsService'
import { reportingService } from './reporting/reportingService'
import { bankDetailsService } from './bankDetails/bankDetails'
import { payoutsService } from './payouts/payoutsService'
import { registriesService } from './registries/registries'
import { organizationService } from './organization/organization'
import { iamService } from './iam/iam'
import { inviteService } from './invite/inviteService'
import { storeService } from './applications/validationSubmission'
import { calendlyService } from './calendly/calendlyService'
import { causeUserService } from './cause-user/causeUserService'

export const services = (capabilities: Capabilities) => ({
  authService: authService(capabilities),
  causeService: causeService(capabilities),
  claimService: claimService(capabilities),
  accountService: accountService(capabilities),
  countriesService: countriesService(capabilities),
  milestonesService: milestonesService(capabilities),
  agentVerificationService: agentVerificationService(capabilities),
  donationsService: donationsService(capabilities),
  reportingService: reportingService(capabilities),
  bankDetailsService: bankDetailsService(capabilities),
  payoutsService: payoutsService(capabilities),
  registriesService: registriesService(capabilities),
  organizationService: organizationService(capabilities),
  iamService: iamService(capabilities),
  inviteService: inviteService(capabilities),
  storeService: storeService(capabilities),
  calendlyService: calendlyService(capabilities),
  causeUserService: causeUserService(capabilities)
})

export type Services = ReturnType<typeof services>
